<template>
    <fieldset class="main-col edit-adult-form" v-if="adult !== null">

        <div class="formInner no-bottom">
            <a class="editAdult editBox" :data-adult-id="index" :id="'adultLink-'+index" :class="{ 'active' : this.editing }" @click="toggleUpdateForm()">
                <p v-html="toggle_title"></p>
            </a>

            <div class="adultForm" :class="{ 'editing' : this.editing, 'hidden' : !this.editing }">
                <div class="level-selector-wrap">
                    <p class="coverHead">Your Partner's level</p>
                    <div class="wrap-x-slide">
                        <div class="level-selector">
                            <template v-for="level in [1, 2, 3, 4, 5]">
                                <label :key="'edit-adult-'+index+'-level-'+level" :class="{ 'active' : adult_cover_level == level }" :data-cy="'edit-adult-'+index+'-level-'+level" @click="updateCoverLevel(level)" >
                                    <span class="level">Level {{ level }}</span>
                                    <span class="prices-wrap">
                                        <span class="amount">&pound;{{ getPriceLevelLabel( level ) }}</span>
                                        <span class="pp" v-html="price_level_label_per_adult"></span>
                                    </span>
                                    <span>
                                        <input v-model="adult_cover_level" :value="level" data-validation="required" data-validation-error-msg="Please select a level of cover" class="level-select your-level-select" type="radio" :name="'adult['+index+'][cover_leve]'" />
                                        <div class="checked"></div>
                                    </span>
                                </label>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="warnings premium-tax">
                    <p class="has-i"><i class="fa fa-info"></i>Premiums include insurance premium tax (IPT).</p>

                    <div class="adultConsent">
                        <p>By submitting an application on behalf of another adult i.e. partner/family member, you confirm that you have their consent to share their personal data with us.</p>
                    </div>
                </div>

                <div class="input-group multiple">
                    <div class="input-group input-title">
                        <label :for="'adultTitle-'+index"><sup>*</sup>Title</label>

                        <div class="select-wrapper">
                            <select :id="'adultTitle-'+index" :name="'adult['+index+'][title]'" v-model="adult_title" :class="{ 'has-error': valid_title !== null && valid_title.valid === false }" @blur="validateTitle()">
                                <option disabled value="">Select</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Ms">Ms</option>
                                <option value="Dr">Dr</option>
                                <option value="n/a">Prefer not to say</option>
                            </select>
                        </div>

                        <span class="help-block form-error" v-if="valid_title !== null && valid_title.valid === false" v-html="valid_title.message" data-cy="adult-title-error"></span>
                    </div>

                    <div class="input-box input-firstname">
                        <label :for="'adultFirstName-'+index"><sup>*</sup>First name(s)</label>
                        <input type="text" :name="'adult['+index+'][firstname]'" :id="'adultFirstName-'+index" v-model="adult_first_name" :class="{ 'has-error': valid_first_name !== null && valid_first_name.valid === false }" @blur="validateFirstName()"/>

                        <span class="help-block form-error" v-if="valid_first_name !== null && valid_first_name.valid === false" v-html="valid_first_name.message" data-cy="edit-adult-firstname-error"></span>
                    </div>

                    <div class="input-box input-lastname">
                        <label :for="'adultLastName-'+index"><sup>*</sup>Surname</label>
                        <input type="text" :name="'adult['+index+'][lastname]'" :id="'adultLastName-'+index" v-model="adult_last_name" :class="{ 'has-error': valid_last_name !== null && valid_last_name.valid === false }" @blur="validateLastName()"/>

                        <span class="help-block form-error" v-if="valid_last_name !== null && valid_last_name.valid === false" v-html="valid_last_name.message" data-cy="edit-adult-lastname-error"></span>
                    </div>
                </div>

                <div class="dob-wrap">
                    <label :for="'adultDOB-'+index"><sup>*</sup>Date of birth</label>
                    <div class="input-group multiple">

                        <div class="select-wrapper sm">
                            <select :name="'adult['+index+'][date_of_birth][day]'" v-model="adult_dob_day" :class="{ 'has-error' : valid_dob.valid === false }">
                                <option disabled value="">DD</option>
                                <option v-for="day in 31" :key="day">{{day}}</option>
                            </select>
                        </div>

                        <div class="select-wrapper sm">
                            <select :name="'adult['+index+'][date_of_birth][month]'" v-model="adult_dob_month" :class="{ 'has-error' : valid_dob.valid === false }">
                                <option disabled value="">MM</option>
                                <option v-for="month in 12" :key="month">{{month}}</option>
                            </select>
                        </div>

                        <div class="select-wrapper sm">
                            <select :name="'adult['+index+'][date_of_birth][year]'" v-model="adult_dob_year" :class="{ 'has-error' : valid_dob.valid === false }">
                                <option disabled value="">YYYY</option>
                                <option v-for="year in dob_year_range" :key="year">{{year}}</option>
                            </select>
                        </div>

                        <span class="help-block form-error" v-if="valid_dob.valid === false" v-html="valid_dob.message" data-cy="edit-adult-dob-error"></span>
                    </div>
                </div>

                <div class="input-group">
                    <div class="input-group input-email">
                        <label :for="'adultEmail-'+index">Email <span class="input-desc">(this email must be different to yours)</span></label>
                        <input type="text" :name="'adult['+index+'][email]'" :id="'adultEmail-'+index" v-model="adult_email" :class="{ 'has-error' : valid_email !== null && valid_email.valid === false }" @blur="validateEmailAddress()" />
                        <span class="help-block form-error" v-if="valid_email !== null && valid_email.valid === false" v-html="valid_email.message" data-cy="edit-adult-email-error"></span>
                    </div>
                </div>

                <div id="adultAddressLines">
                    <div class="input-group multiple">
                        <label :for="'adultAddressLine1-'+index">Address line 1</label>
                        <input v-model="adult.address_line_1" type="text" :name="'adult['+index+'][addressLine1]'" :id="'adultAddressLine1-'+index" :class="{ 'has-error': valid_postcode !== null && valid_postcode.valid === false }" @blur="validatePostcode()" />
                        <span class="help-block form-error" v-if="valid_postcode !== null && valid_postcode.valid === false" v-html="valid_postcode.message" data-cy="edit-adult-postcode-search-error"></span>
                    </div>

                    <label :for="'adultAddressLine2-'+index">Address line 2</label>
                    <input v-model="adult.address_line_2" type="text" :name="'adult['+index+'][addressLine2]'" :id="'adultAddressLine2-'+index" />

                    <label :for="'adultAddressLine3-'+index">Address line 3</label>
                    <input v-model="adult.address_line_3" type="text" :name="'adult['+index+'][addressLine3]'" :id="'adultAddressLine3-'+index" />

                    <label :for="'adultAddressLine4-'+index">Address line 4</label>
                    <input v-model="adult.address_line_4" type="text" :name="'adult['+index+'][addressLine4]'" :id="'adultAddressLine4-'+index" />

                    <div class="postcode-wrap">
                        <label :for="'adultPostcode-'+index">Postcode</label>
                        <div class="input-group multiple">
                            <input v-model="adult.postcode" type="text" :name="'adult['+index+'][postcode]'" :id="'adultPostcode-'+index" :class="{ 'has-error': valid_postcode !== null && valid_postcode.valid === false }" @blur="validatePostcode()" />
                            <span class="help-block form-error" v-if="valid_postcode !== null && valid_postcode.valid === false" v-html="valid_postcode.message" data-cy="edit-adult-postcode-error"></span>
                        </div>
                    </div>
                </div>

                <div class="action-box">
                    <input type="button" value="Remove" class="btn btn-secondary" @click="removeAdultApplicant()" />
                    <input type="button" value="Update" class="btn btn--primary" :disabled="!is_valid" @click="updateAdultApplicant()" />
				</div>
            </div>
        </div>
	</fieldset>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../../../helpers/bus';

    export default {
        props:['adult', 'index'],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            adult_cover_level: {
                get () {
                    return this.adult.cover_level;
                },
                set (value) {
                    this.adult.cover_level = this.sanitizeValue(value);
                }
            },
            adult_title: {
                get () {
                    return this.adult.title;
                },
                set (value) {
                    this.adult.title = this.sanitizeValue(value);
                }
            },
            adult_first_name: {
                get () {
                    return this.adult.first_name;
                },
                set (value) {
                    this.adult.first_name = this.sanitizeValue(value);
                }
            },
            adult_last_name: {
                get () {
                    return this.adult.last_name;
                },
                set (value) {
                    this.adult.last_name = this.sanitizeValue(value);
                }
            },
            adult_email: {
                get () {
                    return this.adult.email;
                },
                set (value) {
                    this.adult.email = value;
                }
            },
            adult_dob_day: {
                get () {
                    return this.adult.date_of_birth.day;
                },
                set (value) {
                    this.adult.date_of_birth.day = this.sanitizeValue(value);
                }
            },
            adult_dob_month: {
                get () {
                    return this.adult.date_of_birth.month;
                },
                set (value) {
                    this.adult.date_of_birth.month = this.sanitizeValue(value);
                }
            },
            adult_dob_year: {
                get () {
                    return this.adult.date_of_birth.year;
                },
                set (value) {
                    this.adult.date_of_birth.year = this.sanitizeValue(value);
                }
            },
            adult_postcode: {
                get () {
                    return this.adult.postcode;
                },
                set (value) {
                    this.adult.postcode = this.sanitizeValue(value);
                }
            },
            ageRestrictionCookieIsSet() {
                var match = document.cookie.match(new RegExp('(^| )remove_age_restriction=([^;]+)'));

                if (match) {
                    return true;
                }
                else {
                    return false;
                }
            },
            dob_year_range: function() {                
                var lower_range = 17,
                    upper_range = 66;

                if( this.ageRestrictionCookieIsSet ) {
                    lower_range = 0;
                    upper_range = 120;
                }

                var top_year = moment().subtract(lower_range, 'years').format('Y'),
                    bottom_year = moment().subtract(upper_range, 'years').format('Y'),
                    years = [],
                    year = 0;


                for(var i = top_year; i >= bottom_year; i--){
                    years[year] = i;
                    year++;
                }

                return years;
            },
            is_valid: function() {
                let vm = this,
                    valid = true;

                if( vm.adult_cover_level === null || vm.adult_cover_level == '' ) valid = false;
                if( vm.valid_title === null || vm.valid_title.valid === false ) valid = false;
                if( vm.valid_first_name === null || vm.valid_first_name.valid === false ) valid = false;
                if( vm.valid_last_name === null || vm.valid_last_name.valid === false ) valid = false;
                if( vm.valid_dob.valid === false || vm.valid_dob.valid === null ) valid = false;
                if( vm.valid_email !== null && vm.valid_email.valid === false ) valid = false;
                if( vm.valid_postcode !== null && vm.valid_postcode.valid === false ) valid = false;

                return valid;
            },
            toggle_title: function(){
                let vm = this,
                    title = "",
                    name = "",
                    dob = "";

                name = vm.cleanName( vm.adult_title, vm.adult_first_name, vm.adult_last_name )
                dob = vm.adult_dob_day + "/" + vm.adult_dob_month + "/" + vm.adult_dob_year;

                title = name +  " - " + dob + " - LEVEL <span class='number'>" + vm.adult_cover_level + "</span>";

                return title;
            },
            valid_dob: function() {
                let vm = this;

                var valid_dob = vm.checkValidDateOfBirth( vm.adult.date_of_birth, 'adult' );

                return valid_dob;
            }
        },
        created() {
        },
        mounted() {
            let vm = this;
            vm.validateTitle();
            vm.validateFirstName();
            vm.validateLastName();
            vm.validateEmailAddress();
            vm.validatePostcode();

            EventBus.$on('updatePrimaryEmailAddress', function(data) {
                vm.validateEmailAddress();
            });

            EventBus.$on('updatePrimaryAddress', function(data) {
                vm.validatePostcode();
            });
        },
        methods: {
            removeAdultApplicant: function(){
                let vm = this;
                vm.$store.dispatch( 'removeAdultApplicant', vm.index );
            },
            toggleUpdateForm: function(){
                let vm = this;
                vm.editing = !vm.editing;
            },
            updateAdultApplicant: function(){
                let vm = this;
                vm.$store.dispatch( 'updateAdultApplicant', [ vm.index, vm.adult ] );
                vm.editing = false;
            },
            updateCoverLevel( cover_level ) {
                let vm = this;
                vm.adult.cover_level = cover_level;
            },
            validateTitle() {
                let vm = this;
                vm.valid_title = vm.checkValidTitle( vm.adult_title, 'adult' );
            },
            validateFirstName() {
                let vm = this;
                vm.valid_first_name = vm.checkValidFirstName( vm.adult_first_name, 'adult' );
            },
            validateLastName() {
                let vm = this;
                vm.valid_last_name = vm.checkValidLastName( vm.adult_last_name, 'adult' );
            },
            validateEmailAddress() {
                let vm = this;
                vm.valid_email = vm.checkValidEmail( vm.adult_email );
            },
            validateAddressLine1() {
                let vm = this;
                vm.valid_address_line_1 = vm.checkValidAddressLine1( vm.adult_address_line_1 );
            },
            validateAddressLine2() {
                let vm = this;
                vm.valid_address_line_2 = vm.checkValidAddressLine2( vm.adult_address_line_2 );
            },
            validatePostcode() {
                let vm = this;
                if( vm.adult.postcode !== null ) {
                    vm.adult.postcode = vm.sanitizeValue(vm.adult.postcode);
                    vm.valid_postcode = vm.checkValidPostcode( vm.adult.postcode, vm.adult.address_line_1, 'adult' );
                }
            },
        },
        data () {
            return {
                editing: false,
                valid_title: null,
                valid_first_name: null,
                valid_last_name: null,
                valid_email: null,
                valid_postcode: null
            }
        }
    }
</script>

import { render, staticRenderFns } from "./application-download-information-document.vue?vue&type=template&id=1d073636&"
import script from "./application-download-information-document.vue?vue&type=script&lang=js&"
export * from "./application-download-information-document.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ciaranwood/Sites/ponderosa/sites/sovereignhealthcare/html/web/app/themes/sovereign/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d073636')) {
      api.createRecord('1d073636', component.options)
    } else {
      api.reload('1d073636', component.options)
    }
    module.hot.accept("./application-download-information-document.vue?vue&type=template&id=1d073636&", function () {
      api.rerender('1d073636', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/scripts/vue/views/blocks/application-download-information-document.vue"
export default component.exports
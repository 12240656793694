var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "floating-container" },
    [
      _c("div", { staticClass: "application-summary-box" }, [
        _c("h2", [_vm._v("Summary")]),
        _vm._v(" "),
        _c("div", { staticClass: "text-wrap total-sum" }, [
          _c("div", { staticClass: "sumCol" }, [
            _c("p", { staticClass: "title" }, [_vm._v("Total premium")]),
            _vm._v(" "),
            _c("p", { staticClass: "short" }, [
              _c("small", {
                domProps: { innerHTML: _vm._s(_vm.summary_line) },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sumCol" }, [
            _vm.is_step_2
              ? _c(
                  "a",
                  {
                    staticClass: "edit",
                    on: {
                      click: function ($event) {
                        return _vm.editStageOne()
                      },
                    },
                  },
                  [_vm._v("Edit")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "price" }, [
              _vm._v("£"),
              _c("span", {
                staticClass: "number",
                domProps: { innerHTML: _vm._s(_vm.total_application_cost) },
              }),
              _c("span", {
                staticClass: "pm",
                domProps: { innerHTML: _vm._s(_vm.price_level_label_simple) },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "move-btn" }, [
          _vm.is_step_1
            ? _c(
                "a",
                {
                  staticClass: "btn btn--primary",
                  on: {
                    click: function ($event) {
                      return _vm.completeStageOne()
                    },
                  },
                },
                [_vm._v("Next")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.is_step_2
            ? _c(
                "a",
                {
                  staticClass: "btn btn--primary",
                  on: {
                    click: function ($event) {
                      return _vm.completeStageTwo()
                    },
                  },
                },
                [_vm._v("Next")]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("h3", [
            _c(
              "a",
              {
                staticClass: "expand-link",
                on: {
                  click: function ($event) {
                    return _vm.toggleExpandBox()
                  },
                },
              },
              [_vm._v("Your cover details")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "expanable open" }, [
            _c(
              "div",
              { staticClass: "fixed-height" },
              [_c("application-summary-floating")],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("application-download-terms"),
      _vm._v(" "),
      _c("application-download-information-document"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="level-selector-wrap">
        <div class="level-selector-wrap__header">
            <p>Cover level</p>
            <button @click="toggleCompareLevels()" class="compare-button" :class="{ 'compare-button--open' : view_full_table }" v-html="compare_button_text"></button>
        </div>
        <div class="wrap-x-slide">
            <div class="level-selector" v-if="!view_full_table">
                <template v-for="level in [1, 2, 3, 4 ,5]">
                    <label :key="'your-level-'+level" :class="{ 'active' : application.cover_level == level }" @click="updateCoverLevel(level)">
                        <span class="level">Level {{ level }}</span>
                        <span class="prices-wrap">
                            <span class="amount">&pound;{{ getPriceLevelLabel( level ) }}</span>
                            <span class="pp" v-html="price_level_label_per_adult"></span>
                        </span>
                        <span>
                            <input v-model="application.cover_level" :value="level" data-validation="required" data-validation-error-msg="Please select a level of cover" class="level-select your-level-select" type="radio" name="main[level]" />
                            <div class="checked"></div>
                        </span>
                    </label>
                </template>
            </div>
            <template v-if="view_full_table">
                <payroll-table :benefits="benefits"></payroll-table>
            </template>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../helpers/bus';

    import PayrollTable from './application-level-table.vue';

    export default {
        props:[],
        components:{
            PayrollTable
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            compare_button_text() {
                let vm = this;

                if( vm.view_full_table ) {
                    return 'Close comparison';
                }

                return 'Compare levels';

            }
        },
        created() {
        },
        mounted() {
            let vm = this,
                benefits_api_url = '/wp-json/sovereign/v1/get-benefits';

            axios.get(benefits_api_url)
                .then(response => {
                    if( response.status == '200' ) {
                        vm.benefits = response.data;
                    }
                })
                .catch( error => { });

            EventBus.$on('updateCoverLevel', function(data) {
                vm.selected_level = vm.application.cover_level;
            });
        },
        methods: {
            toggleCompareLevels() {
                let vm = this;
                vm.view_full_table = !vm.view_full_table;
            },
            updateCoverLevel( cover_level ) {
                let vm = this;
                vm.$store.dispatch( 'updateCoverLevel', cover_level );
                EventBus.$emit('updateCoverLevel');
            }
        },
        data () {
            return {
                benefits: false,
                view_full_table: false
            }
        }
    }
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fieldset", { staticClass: "main-col add-child-form" }, [
    _c("div", { staticClass: "formInner no-bottom" }, [
      _c(
        "a",
        {
          staticClass: "addChild addBox",
          class: {
            active: this.editing,
            disabled:
              Object.keys(_vm.application.additional_policies.children)
                .length >= 4,
          },
          on: {
            click: function ($event) {
              return _vm.toggleAddForm()
            },
          },
        },
        [_vm._v("Add up to four dependent children aged under 18 for free")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "childForm",
          class: { editing: this.editing, hidden: !this.editing },
        },
        [
          _c("div", { staticClass: "warnings premium-tax" }, [
            _vm._m(0),
            _vm._v(" "),
            Object.keys(_vm.application.additional_policies.children).length < 1
              ? _c("div", { staticClass: "childConsent" }, [
                  _c("p", [
                    _vm._v(
                      "As a parent or legal guardian of the dependent child, you confirm you have the authority to share their personal data on their behalf."
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group multiple" }, [
            _c("div", { staticClass: "input-group input-title" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "select-wrapper" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.child.title,
                        expression: "child.title",
                      },
                    ],
                    attrs: {
                      name: "child[title]",
                      id: "childTitle",
                      "data-cy": "add-child-title",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.validateTitle()
                      },
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.child,
                          "title",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { disabled: "", value: "" } }, [
                      _vm._v("Select"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Miss" } }, [
                      _vm._v("Miss"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Master" } }, [
                      _vm._v("Master"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.valid_title !== null && _vm.valid_title.valid === false
                ? _c("span", {
                    staticClass: "help-block form-error",
                    attrs: { "data-cy": "add-child-title-error" },
                    domProps: { innerHTML: _vm._s(_vm.valid_title.message) },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-box input-firstname" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.child.first_name,
                    expression: "child.first_name",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "child[firstname]",
                  id: "childFirstName",
                  "data-cy": "add-child-firstname",
                },
                domProps: { value: _vm.child.first_name },
                on: {
                  blur: function ($event) {
                    return _vm.validateFirstName()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.child, "first_name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.valid_first_name !== null &&
              _vm.valid_first_name.valid === false
                ? _c("span", {
                    staticClass: "help-block form-error",
                    attrs: { "data-cy": "add-child-firstname-error" },
                    domProps: {
                      innerHTML: _vm._s(_vm.valid_first_name.message),
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-box input-lastname" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.child.last_name,
                    expression: "child.last_name",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "child[lastname]",
                  id: "childLastName",
                  "data-cy": "add-child-lastname",
                },
                domProps: { value: _vm.child.last_name },
                on: {
                  blur: function ($event) {
                    return _vm.validateLastName()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.child, "last_name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.valid_last_name !== null &&
              _vm.valid_last_name.valid === false
                ? _c("span", {
                    staticClass: "help-block form-error",
                    attrs: { "data-cy": "add-child-lastname-error" },
                    domProps: {
                      innerHTML: _vm._s(_vm.valid_last_name.message),
                    },
                  })
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dob-wrap" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "input-group multiple" }, [
              _c("div", { staticClass: "select-wrapper sm" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.child.date_of_birth.day,
                        expression: "child.date_of_birth.day",
                      },
                    ],
                    class: { "has-error": _vm.valid_dob.valid === false },
                    attrs: {
                      name: "child[date_of_birth][day]",
                      "data-cy": "add-child-dob-day",
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.child.date_of_birth,
                            "day",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.updateDOB("day")
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { disabled: "", value: "" } }, [
                      _vm._v("DD"),
                    ]),
                    _vm._v(" "),
                    _vm._l(31, function (day) {
                      return _c("option", { key: day }, [_vm._v(_vm._s(day))])
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "select-wrapper sm" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.child.date_of_birth.month,
                        expression: "child.date_of_birth.month",
                      },
                    ],
                    class: { "has-error": _vm.valid_dob.valid === false },
                    attrs: {
                      name: "child[date_of_birth][month]",
                      "data-cy": "add-child-dob-month",
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.child.date_of_birth,
                            "month",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.updateDOB("month")
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { disabled: "", value: "" } }, [
                      _vm._v("MM"),
                    ]),
                    _vm._v(" "),
                    _vm._l(12, function (month) {
                      return _c("option", { key: month }, [
                        _vm._v(_vm._s(month)),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "select-wrapper sm" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.child.date_of_birth.year,
                        expression: "child.date_of_birth.year",
                      },
                    ],
                    class: { "has-error": _vm.valid_dob.valid === false },
                    attrs: {
                      name: "child[date_of_birth][year]",
                      "data-cy": "add-child-dob-year",
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.child.date_of_birth,
                            "year",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.updateDOB("year")
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { disabled: "", value: "" } }, [
                      _vm._v("YYYY"),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.dob_year_range, function (year) {
                      return _c("option", { key: year }, [_vm._v(_vm._s(year))])
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _vm.valid_dob.valid === false
                ? _c("span", {
                    staticClass: "help-block form-error",
                    attrs: { "data-cy": "add-child-dob-error" },
                    domProps: { innerHTML: _vm._s(_vm.valid_dob.message) },
                  })
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "action-box" }, [
            _c("input", {
              staticClass: "btn btn-secondary",
              attrs: { type: "button", value: "Remove" },
              on: {
                click: function ($event) {
                  return _vm.removeChildApplicant()
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "btn btn--primary",
              attrs: {
                type: "button",
                value: "Confirm",
                disabled: !_vm.is_valid,
              },
              on: {
                click: function ($event) {
                  return _vm.addChildApplicant()
                },
              },
            }),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "has-i" }, [
      _c("i", { staticClass: "fa fa-info" }),
      _vm._v(
        "Add up to four dependent children up to the age of 18 who live with you below."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "childTitle" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Title"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "childFirstName" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("First name(s)"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "childLastName" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Surname"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "childDob" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Date of birth"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
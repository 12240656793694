<template>
    <thead>
        <tr class="header">
            <td colspan="3" class="al">Levels of cover</td>
            <td :class="isLevelActive(1)" class="tab-level-info tab-level-1" data-level="1" @mouseover="hoverColumns(1)" @mouseleave="clearColumns()">Level 1</td>
            <td :class="isLevelActive(2)" class="tab-level-info tab-level-2" data-level="2" @mouseover="hoverColumns(2)" @mouseleave="clearColumns()">Level 2</td>
            <td :class="isLevelActive(3)" class="tab-level-info tab-level-3" data-level="3" @mouseover="hoverColumns(3)" @mouseleave="clearColumns()">Level 3</td>
            <td :class="isLevelActive(4)" class="tab-level-info tab-level-4" data-level="4" @mouseover="hoverColumns(4)" @mouseleave="clearColumns()">Level 4</td>
            <td :class="isLevelActive(5)" class="tab-level-info tab-level-5" data-level="5" @mouseover="hoverColumns(5)" @mouseleave="clearColumns()">Level 5</td>
        </tr>
        <tr>
            <td colspan="3" class="al" v-html="price_level_label"></td>
            <td :class="isLevelActive(1)" class="tab-level-info tab-level-1" data-level="1" @mouseover="hoverColumns(1)" @mouseleave="clearColumns()"><span class="money">&pound;{{ getPriceLevelLabel( 1 ) }}</span></td>
            <td :class="isLevelActive(2)" class="tab-level-info tab-level-2 alt" data-level="2" @mouseover="hoverColumns(2)" @mouseleave="clearColumns()"><span class="money">&pound;{{ getPriceLevelLabel( 2 ) }}</span></td>
            <td :class="isLevelActive(3)" class="tab-level-info tab-level-3" data-level="3" @mouseover="hoverColumns(3)" @mouseleave="clearColumns()"><span class="money">&pound;{{ getPriceLevelLabel( 3 ) }}</span></td>
            <td :class="isLevelActive(4)" class="tab-level-info tab-level-4 alt" data-level="4" @mouseover="hoverColumns(4)" @mouseleave="clearColumns()"><span class="money">&pound;{{ getPriceLevelLabel( 4 ) }}</span></td>
            <td :class="isLevelActive(5)" class="tab-level-info tab-level-5 " data-level="5" @mouseover="hoverColumns(5)" @mouseleave="clearColumns()"><span class="money">&pound;{{ getPriceLevelLabel( 5 ) }}</span></td>
        </tr>
        <tr v-if="buttons">
            <td colspan="3" class="al">Choose a level</td>
            <td :class="isLevelActive(1)" class="tab-level-info tab-level-1" data-level="1" @mouseover="hoverColumns(1)" @mouseleave="clearColumns()">
                <a class="choice" @click="ctaHandler('1')">{{button_label}}</a>
            </td>
            <td :class="isLevelActive(2)" class="tab-level-info tab-level-2 alt" data-level="2" @mouseover="hoverColumns(2)" @mouseleave="clearColumns()">
                <a class="choice" @click="ctaHandler('2')">{{button_label}}</a>
            </td>
            <td :class="isLevelActive(3)" class="tab-level-info tab-level-3" data-level="3" @mouseover="hoverColumns(3)" @mouseleave="clearColumns()">
                <a class="choice" @click="ctaHandler('3')">{{button_label}}</a>
            </td>
            <td :class="isLevelActive(4)" class="tab-level-info tab-level-4 alt" data-level="4" @mouseover="hoverColumns(4)" @mouseleave="clearColumns()">
                <a class="choice" @click="ctaHandler('4')">{{button_label}}</a>
            </td>
            <td :class="isLevelActive(5)" class="tab-level-info tab-level-5" data-level="5" @mouseover="hoverColumns(5)" @mouseleave="clearColumns()">
                <a class="choice" @click="ctaHandler('5')">{{button_label}}</a>
            </td>
        </tr>
    </thead>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../../../helpers/bus';

    export default {
        props:[ 'buttons', 'buttonType' ],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            price_level_label: function() {
                let vm = this;
                var label = '';

                label = 'Monthly premium (per adult)';

                return label;
            },
            button_label: function() {
                return this.buttonType && this.buttonType.toLowerCase() == 'apply' ? 'Apply Now' : 'Choose';
            },
            button_type: function() {
                return this.buttonType && this.buttonType.toLowerCase() == 'apply' ? 'apply' : 'choose';
            }
        },
        created() {
            let vm = this,
                api_url = '/wp-json/sovereign/v1/get-pricing-levels',
                pricing_levels = null;

            axios.get(api_url)
                .then(response => {
                    if( response.status == '200' ) {
                        pricing_levels = response.data;
                        vm.$store.dispatch( 'updatePrices', pricing_levels );
                    }
                })
                .catch( error => { });
        },
        mounted() {

        },
        methods: {
            clearColumns() {
                jQuery('.tab-level-info').removeClass('hovering');
            },
            hoverColumns( index ) {
                jQuery('.tab-level-info').removeClass('hovering');
                jQuery('.tab-level-'+index).addClass('hovering');
            },
            updateCoverLevel( cover_level, next_page = false ) {
                let vm = this;

                vm.$store.dispatch( 'updateCoverLevel', cover_level );
                EventBus.$emit('updateCoverLevel');

                if( next_page ) {
                    vm.$store.dispatch( 'validateApplication', [ 'cover_level', cover_level ] );

                    vm.$store.dispatch( 'updateStage', [ 'step-1', true ] );
                    history.pushState(null, 'Step 1', window.location.pathname + '?stage=step-1');

                    EventBus.$emit('updateStageURL');
                };
            },
            ctaHandler( cover_level ) {
                let vm = this;

                if( vm.button_type == 'choose' ) {
                    vm.updateCoverLevel(cover_level, true)
                } else if( vm.button_type == 'apply' ) {
                    vm.goToApply(cover_level);
                } else {
                    vm.updateCoverLevel(cover_level);
                };
            },
            isLevelActive : function(level) {
                let vm = this;

                if( vm.application.cover_level ) {
                    return vm.application.cover_level == level ? 'active' : '';
                } else {
                    return level == 1 ? 'active' : '';
                };
            },
            goToApply(cover_level)
            {
                location.href = window.location.origin + '/personal/apply-now/?level=' + cover_level;
            }
        },
        data () {
            return {
            }
        }
    }
</script>

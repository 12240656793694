var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-col" }, [
    _c("div", { staticClass: "formInner adults-summary" }, [
      _c("div", { staticClass: "expander-box can-expand" }, [
        _vm._m(0),
        _vm._v(" "),
        Object.keys(_vm.application.additional_policies.adults).length > 0
          ? _c(
              "div",
              { staticClass: "content" },
              [
                _vm._l(
                  _vm.application.additional_policies.adults,
                  function (adult, adult_key) {
                    return _c(
                      "div",
                      {
                        key: "adult_" + adult_key,
                        staticClass: "adult",
                        attrs: { "data-cy": "summary-adult-" + adult_key },
                      },
                      [
                        _c("p", [
                          _c("strong", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.cleanName(
                                  adult.title,
                                  adult.first_name,
                                  adult.last_name
                                )
                              ),
                            },
                          }),
                        ]),
                        _c("p"),
                        _c("p", [
                          _c("strong", [_vm._v("Cover level:")]),
                          _vm._v(" Level " + _vm._s(adult.cover_level)),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.getPremiumLabel()) + ":"),
                          ]),
                          _vm._v(
                            " £" +
                              _vm._s(_vm.getCurrentPrice(adult.cover_level))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("strong", [_vm._v("Date of birth:")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.getDateOfBirth(adult.date_of_birth))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("strong", [_vm._v("Email:")]),
                          _vm._v(" " + _vm._s(adult.email)),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("strong", [_vm._v("Address:")]),
                          _vm._v(" " + _vm._s(_vm.getAdultAddress(adult))),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("strong", [_vm._v("Postcode:")]),
                          _vm._v(" " + _vm._s(adult.postcode)),
                        ]),
                      ]
                    )
                  }
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--primary",
                    on: {
                      click: function ($event) {
                        return _vm.returnToStepOne("edit")
                      },
                    },
                  },
                  [_vm._v("Edit")]
                ),
              ],
              2
            )
          : _c(
              "div",
              { staticClass: "content", attrs: { "data-cy": "summary-adult" } },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--primary",
                    on: {
                      click: function ($event) {
                        return _vm.returnToStepOne("add")
                      },
                    },
                  },
                  [_vm._v("Edit")]
                ),
              ]
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _vm._v("Your Partner's/Family Member's Cover"),
      _c("a", { staticClass: "expand arrowDown" }, [_vm._v("Expand")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "no-content" }, [
      _c("p", [
        _c("i", { staticClass: "fa fa-info" }),
        _vm._v("No adults added"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
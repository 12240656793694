var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showConfirmation()
        ? _c("payroll-confirmation")
        : _vm.showStepThree()
        ? _c("payroll-stage-three")
        : _vm.showStepTwo()
        ? _c("payroll-stage-two")
        : _vm.showStepOne()
        ? _c("payroll-stage-one")
        : _c("payroll-get-started"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
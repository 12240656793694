import { render, staticRenderFns } from "./application-form-primary.vue?vue&type=template&id=632032fc&"
import script from "./application-form-primary.vue?vue&type=script&lang=js&"
export * from "./application-form-primary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ciaranwood/Sites/ponderosa/sites/sovereignhealthcare/html/web/app/themes/sovereign/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('632032fc')) {
      api.createRecord('632032fc', component.options)
    } else {
      api.reload('632032fc', component.options)
    }
    module.hot.accept("./application-form-primary.vue?vue&type=template&id=632032fc&", function () {
      api.rerender('632032fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/scripts/vue/views/pages/stage-one/blocks/application-form-primary.vue"
export default component.exports
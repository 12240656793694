var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "application-form-wrap", class: { loading: _vm.loading } },
    [
      _vm.loading ? _c("span", { staticClass: "loading-spinner" }) : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "application-stage application-thank-you" }, [
        _c("div", { staticClass: "expander-box" }, [
          _c("h2", [_vm._v("Thank you for your application")]),
          _vm._v(" "),
          _c("div", {
            staticClass: "content active",
            domProps: { innerHTML: _vm._s(_vm.thank_you) },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
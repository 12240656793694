<template>
    <div class="application-form-wrap" :class="{ 'loading' : loading }">
        <span class="loading-spinner" v-if="loading"></span>
        <div class="application-stage application-thank-you">
            <div class="expander-box">
                <h2>Thank you for your application</h2>
                <div class="content active" v-html="thank_you"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
        },
        created() {
            let vm = this,
                api_url = '/wp-json/acf/v3/options/option';
                
            axios.get(api_url)
                .then(response => { 
                    if( response.status == '200' ) {
                        vm.thank_you = response.data.acf.app_step_complete_thank_you;
                        
                        setTimeout( function(){
                            vm.loading = false;
                        }, 500);
                    }
                })
                .catch( error => { });
        },
        mounted() {
            var vm = this;
            // vm.$ga.page({
            //     page: '/thank-you/',
            //     title: 'Thank You',
            //     location: window.location.origin + '/?stage=thank-you'
            // });
        },
        methods: {
        },
        data () {
            return {
                loading: true,
                thank_you: '',
            }
        }
    }
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-col" }, [
    _c("div", { staticClass: "formInner payroll-summary" }, [
      _c("div", { staticClass: "expander-box can-expand" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("p", [
            _c("strong", [_vm._v("Account holder name(s):")]),
            _vm._v(" "),
            _c("span", { attrs: { "data-cy": "summary-account-name" } }, [
              _vm._v(_vm._s(_vm.application.account_holder_name)),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Sort code:")]),
            _vm._v(" "),
            _c("span", { attrs: { "data-cy": "summary-sort-code" } }, [
              _vm._v(_vm._s(_vm.sort_code)),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Account number:")]),
            _vm._v(" "),
            _c("span", { attrs: { "data-cy": "summary-account-number" } }, [
              _vm._v(_vm._s(_vm.application.account_number)),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Monthly payment:")]),
            _vm._v(
              " £" + _vm._s(_vm.getCurrentPrice(_vm.application.cover_level))
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn--primary",
              on: {
                click: function ($event) {
                  return _vm.returnToStepTwo()
                },
              },
            },
            [_vm._v("Edit")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _vm._v("Your Direct Debit Details"),
      _c("a", { staticClass: "expand arrowDown" }, [_vm._v("Expand")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
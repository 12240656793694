<template>
    <div class="application-form-wrap" :class="{ 'loading' : loading }">
        <span class="loading-spinner" v-if="loading"></span>
        <div class="application-stage application-stage--one">
            <application-step-indicator></application-step-indicator>

            <div class="application-stage__level-selector">
                <application-level-selector></application-level-selector>
            </div>

            <form id="appFormOne" class="application-form" method="post" novalidate="true">

                <div class="main-col validation-error-col" v-if="show_errors && is_stage_one_valid === false">
                    <div class="main-error">
                        <p>Please ensure all required fields are completed</p>
                    </div>
                </div>

                <application-form-primary></application-form-primary>

                <!-- Loop through all Adults -->
                <application-form-edit-adults v-for="(adult, adult_key) in application.additional_policies.adults" :adult="adult" :key="'adult-'+adult_key" :index="adult_key"></application-form-edit-adults>
                <!-- Add Adults -->
                <application-form-add-adults></application-form-add-adults>

                <!-- Loop through all Children -->
                <application-form-edit-children v-for="(child, child_key) in application.additional_policies.children" :child="child" :key="'child-'+child_key" :index="child_key"></application-form-edit-children>
                <!-- Add Children -->
                <application-form-add-children></application-form-add-children>

                <application-important-notes></application-important-notes>
                <application-summary-box></application-summary-box>

            </form>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../../helpers/bus';

    import ApplicationStepIndicator from '../../blocks/application-step-indicator';
    import ApplicationLevelSelector from '../../blocks/application-level-selector';

    import ApplicationFormPrimary from './blocks/application-form-primary';

    import ApplicationFormAddAdults from './blocks/application-form-add-adults';
    import ApplicationFormEditAdults from './blocks/application-form-edit-adults';

    import ApplicationFormAddChildren from './blocks/application-form-add-children';
    import ApplicationFormEditChildren from './blocks/application-form-edit-children';

    import ApplicationImportantNotes from '../../blocks/application-important-notes';
    import ApplicationSummaryBox from '../../blocks/application-summary-box';

    import ApplicationDownloadTerms from '../../blocks/application-download-terms';

    export default {
        props:[],
        components:{
            ApplicationStepIndicator,
            ApplicationLevelSelector,
            ApplicationFormPrimary,
            ApplicationFormAddAdults,
            ApplicationFormEditAdults,
            ApplicationFormAddChildren,
            ApplicationFormEditChildren,
            ApplicationImportantNotes,
            ApplicationSummaryBox,
            ApplicationDownloadTerms
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            adult_count: function(){
                let vm = this,
                    count = 0;

                if( vm.application !== null ) {
                    count = Object.keys(vm.application.additional_policies.adults).length
                }

                return count;
            },
            children_count: function(){
                let vm = this,
                    count = 0;

                if( vm.application !== null ) {
                    count = Object.keys(vm.application.additional_policies.children).length
                }

                return count;
            }
        },
        created() {
        },
        mounted() {
            let vm = this;

            setTimeout( function(){
                vm.loading = false;
            }, 500);

            setTimeout( function(){
                var offset = 0;
                if(window.location.hash) {
                    if( window.location.hash == '#add-adults' ) {
                        offset = jQuery('.add-adult-form').offset().top - 100;
                    } else if( window.location.hash == '#edit-adults' ) {
                        offset = jQuery('.edit-adult-form').offset().top - 100;
                    } else if( window.location.hash == '#add-children' ) {
                        offset = jQuery('.add-child-form').offset().top - 100;
                    } else if( window.location.hash == '#edit-children' ) {
                        offset = jQuery('.edit-child-form').offset().top - 100;
                    }
                    window.scrollTo( 0, offset );
                }
            }, 1000);

            EventBus.$on('validateAllStep1', function(data) {
                vm.validateStep1();
                vm.show_errors = true;
            });
        },
        methods: {
            validateStep1() {
                let vm = this;

                vm.$store.dispatch( 'validateApplication', [ 'title', vm.application.title ] );
                vm.$store.dispatch( 'validateApplication', [ 'first_name', vm.application.first_name ] );
                vm.$store.dispatch( 'validateApplication', [ 'last_name', vm.application.last_name ] );

                vm.$store.dispatch( 'validateApplicationDOB', [ 'dob_day', vm.application.date_of_birth.day ] );
                vm.$store.dispatch( 'validateApplicationDOB', [ 'dob_month', vm.application.date_of_birth.month ] );
                vm.$store.dispatch( 'validateApplicationDOB', [ 'dob_year', vm.application.date_of_birth.year ] );

                vm.$store.dispatch( 'validateApplicationEmailAddress', [ 'email_address', vm.application.email_address ] );
                vm.$store.dispatch( 'validateApplicationPhoneNumber', [ 'phone_number', vm.application.phone_number ] );

                jQuery('#mainAddressLines').removeClass('hidden');
                jQuery('.reveal-address--main').hide();
                vm.$store.dispatch( 'validateApplication', [ 'address_line_1', vm.application.address.line_1 ] );
                vm.$store.dispatch( 'validateApplication', [ 'address_line_2', vm.application.address.line_2 ] );
                vm.$store.dispatch( 'validateApplicationPostcode', [ 'postcode', vm.application.address.postcode ] );

                vm.$store.dispatch( 'validateApplication', [ 'where_did_you_hear', vm.application.where_did_you_hear ] );
            },
        },
        data () {
            return {
                loading: true,
                show_errors: false
            }
        }
    }
</script>
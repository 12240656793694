var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-col" }, [
    _c("div", { staticClass: "formInner primary-summary" }, [
      _c("div", { staticClass: "expander-box can-expand" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "content active" }, [
          _c("p", [
            _c("strong", {
              attrs: { "data-cy": "summary-name" },
              domProps: {
                innerHTML: _vm._s(
                  _vm.cleanName(
                    _vm.application.title,
                    _vm.application.first_name,
                    _vm.application.last_name
                  )
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Cover level:")]),
            _vm._v(" Level "),
            _c("span", { attrs: { "data-cy": "summary-level" } }, [
              _vm._v(_vm._s(_vm.application.cover_level)),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.getPremiumLabel()) + ":")]),
            _vm._v(" "),
            _c("span", { attrs: { "data-cy": "summary-cover-price" } }, [
              _vm._v(
                "£" + _vm._s(_vm.getCurrentPrice(_vm.application.cover_level))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Date of birth:")]),
            _vm._v(" "),
            _c("span", { attrs: { "data-cy": "summary-dob" } }, [
              _vm._v(_vm._s(_vm.date_of_birth)),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Phone:")]),
            _vm._v(" "),
            _c("span", { attrs: { "data-cy": "summary-phone" } }, [
              _vm._v(_vm._s(_vm.application.phone_number)),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Email:")]),
            _vm._v(" "),
            _c("span", { attrs: { "data-cy": "summary-email" } }, [
              _vm._v(_vm._s(_vm.application.email_address)),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Address:")]),
            _vm._v(" "),
            _c("span", { attrs: { "data-cy": "summary-address" } }, [
              _vm._v(_vm._s(_vm.main_address)),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Postcode:")]),
            _vm._v(" "),
            _c("span", { attrs: { "data-cy": "summary-postcode" } }, [
              _vm._v(_vm._s(_vm.application.address.postcode)),
            ]),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm.application.recommended_policy_no !== null &&
          _vm.application.recommended_policy_no !== ""
            ? _c("p", [
                _c("strong", [_vm._v("Introducer’s policy number:")]),
                _vm._v(" "),
                _c("span", { attrs: { "data-cy": "summary-promo-code" } }, [
                  _vm._v(_vm._s(_vm.application.recommended_policy_no)),
                ]),
              ])
            : _c("p", [
                _c("strong", [_vm._v("Introducer’s policy number:")]),
                _vm._v(" n/a"),
              ]),
          _vm._v(" "),
          _vm.application.offer_code !== null &&
          _vm.application.offer_code !== ""
            ? _c("p", [
                _c("strong", [_vm._v("Offer code:")]),
                _vm._v(" "),
                _c("span", { attrs: { "data-cy": "summary-offer-code" } }, [
                  _vm._v(_vm._s(_vm.application.offer_code)),
                ]),
              ])
            : _c("p", [_c("strong", [_vm._v("Offer code:")]), _vm._v(" n/a")]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [
              _vm._v("How did you hear about Sovereign Health Care?:"),
            ]),
            _vm._v(" "),
            _c("span", { attrs: { "data-cy": "summary-referrer" } }, [
              _vm._v(_vm._s(_vm.application.where_did_you_hear)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn--primary",
              attrs: { "data-cy": "summary-edit-primary-button" },
              on: {
                click: function ($event) {
                  return _vm.returnToStepOne()
                },
              },
            },
            [_vm._v("Edit")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _vm._v("Your Cover"),
      _c("a", { staticClass: "expand arrowDown active" }, [_vm._v("Expand")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="outlined">
        <a target="_blank" href="/app/uploads/2023/12/@Good_All_Round_TCs_secured_20231101.pdf">
            <img :src="this.images_path+'/icon-download.png'" />
            Click here to download the cash plan terms and conditions.
        </a>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            )
        },
        created() {
        },
        mounted() {
        },
        methods: {
        },
        data () {
            return {
            }
        }
    }
</script>

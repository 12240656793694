var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "application-form-wrap", class: { loading: _vm.loading } },
    [
      _vm.loading ? _c("span", { staticClass: "loading-spinner" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "application-stage stage-three" },
        [
          _c("application-step-indicator"),
          _vm._v(" "),
          _c("application-level-selector"),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "application-form",
              attrs: { id: "appFormThree", method: "post" },
            },
            [
              _c("div", { staticClass: "main-col summary-large-text" }, [
                _c("div", {
                  staticClass: "formInner",
                  domProps: { innerHTML: _vm._s(_vm.check_details) },
                }),
              ]),
              _vm._v(" "),
              _c("application-summary-primary"),
              _vm._v(" "),
              _c("application-summary-adults"),
              _vm._v(" "),
              _c("application-summary-children"),
              _vm._v(" "),
              _c("application-summary-direct-debit"),
              _vm._v(" "),
              _c("application-declaration"),
              _vm._v(" "),
              _c("application-summary-final"),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="main-col">
        <div class="formInner payroll-summary">
            <div class="expander-box can-expand">
                <h2>Your Direct Debit Details<a class="expand arrowDown">Expand</a></h2>
                <div class="content">
                    <p><strong>Account holder name(s):</strong> <span data-cy="summary-account-name">{{ application.account_holder_name }}</span></p>
                    <p><strong>Sort code:</strong> <span data-cy="summary-sort-code">{{ sort_code }}</span></p>
                    <p><strong>Account number:</strong> <span data-cy="summary-account-number">{{ application.account_number }}</span></p>
                    <p><strong>Monthly payment:</strong> &pound;{{ getCurrentPrice(application.cover_level) }}</p>
                    <a class="btn btn--primary" @click="returnToStepTwo()">Edit</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            sort_code: function() {
                let vm = this;
                
                return vm.application.sort_code[0] + '-' + vm.application.sort_code[1] + '-' + vm.application.sort_code[2];
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            returnToStepTwo( ) {
                let vm = this;
                vm.$store.dispatch( 'updateStage', [ 'step-2', true ] );

                history.pushState(null, 'Step 2', window.location.pathname + '?stage=step-2');
                EventBus.$emit('updateStageURL');
            }
        },
        data () {
            return {
            }
        }
    }
</script>
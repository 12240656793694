import "@babel/polyfill";
require('es6-promise').polyfill();
import axios from "axios";

import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
import Swiper from 'swiper/swiper-bundle';

// import styles bundle
import 'swiper/swiper-bundle.css';

import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

import Cookies from 'js-cookie';

window.axios = axios;

window.Vue = Vue;
window.Vuex = Vuex;
window.moment = moment;

require("./vue/vue.js");

//jQuery wrapper
(function ($) {

	// Document ready
	$(document).on( 'ready', function () {

		if( jQuery('.example-wrapper').length ) {
			new SimpleBar(jQuery('.example-wrapper')[0], { autoHide: false });
		}

		// Javascript is enabled
		$('html.ie').removeClass('no-js').addClass('js');
		$('#nojs-notice').remove();

		// IE classes
		if (/*@cc_on!@*/false) {
			document.documentElement.className += ' ie' + document.documentMode;
		}

		__init();

		$(window).resize(function () {
			__resizeWindowEvents();
		});

		$(window).scroll(function () {
			__onScrollEvents();
		});

		window.addEventListener('orientationchange', function () {
			__floatingSide();
		});

		__infiniteNewsPosts('.feed', '.loading-spinner');

	});

	var __init = function () {

		__contactFormReveal();
		__bannerSlider();
		__heroBannerSlider();
		__accordion();
		__searchTakeover();

		__slidetoggle();
		__mobilemenu();
		__aboutnav();

		__applicationHelpers();
		__applicationSummary();

		setTimeout(__accordionOpener, 500);

		__basicTableWrap();
		__floatingSide();

		__youtubeiframes();
		__downloadsExplorer();
		__videoModal();
		__formSlideOut();
		__toggleNavBasedOnScroll();

		$(window).on('resize', function(){
			__toggleNavBasedOnScroll();
		});
	}

	var __heroBannerSlider = function () {
		const slider = new Swiper( '.js-banner__slider', {
			slidesPerView: 1,
			spaceBetween: 0,
			autoplay: {
				delay: 5000,
			},
			loop: $(this).length > 1,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				type: 'bullets',
			},
			watchOverflow: true
		});
	}

	var __downloadsExplorer = function () {
		var $downloadsExplorer = $('.js-downloads-explorer');

		$downloadsExplorer.each(function(index, element){
			const $slider = $(element).find('.js-downloads-explorer__slider');

			if ($(element).find('.js-downloads-explorer__slide').length > 3){
				const slider = new Swiper($slider[0], {
					slidesPerView: 1,
					spaceBetween: 0,
					loop: false,
					breakpoints: {
						768: {
							slidesPerView: 3,
							spaceBetween: 30
						},
						1024: {
							slidesPerView: 3.5,
							spaceBetween: 50
						}
					}
				});

				$(element).find('.swiper-button-next').on('click', function(e){
					e.preventDefault();

					slider.slideNext();
				});
			} else {
				$downloadsExplorer.addClass('no-slider');
			}
		});
	}

	var __formSlideOut = function () {
		var $toggle = $('.js-contact-sidebar__toggle');
		var $mobileToggle = $('.js-contact-sidebar__toggle--mobile');

		updateBodyScroll($('.js-contact-sidebar').hasClass('is-active'));

		$toggle.on('click', function(e){
			e.preventDefault();

			$('.js-contact-sidebar').toggleClass('is-active');
			$mobileToggle.toggleClass('is-active');

			updateBodyScroll($('.js-contact-sidebar').hasClass('is-active'))
		});

		function updateBodyScroll(toggleStatus) {
			let $body = $('html, body');

			if (toggleStatus) {
				$body.css({
					'overflow': 'hidden'
				});
			} else {
				$body.css({
					'overflow': ''
				});
			}
		}
	}

	var __videoModal = function () {
		$('.js-video-modal').on('click', function(e){
			e.preventDefault();

			let modalID = $(this).data('modal-id');

			$('#' + modalID).bPopup({
				easing: 'easeOutBack',
				speed: 450,
				positionStyle: 'absolute',
				modalClose: true
			});
		});
	}

	var __basicTableWrap = function () {
		var table = $('.full-width-table');
		table.wrap('<div class="example-wrapper" />');
	}

	var __applicationSummary = function () {
		if ($(window).width() >= 1024) {

			$(window).scroll(function (e) {
				__floatingSide();
			});

			$(window).resize(function (e) {
				__floatingSide();
			})
		}

	}

	var __floatingSide = function () {

		if ($('.floating-container').length > 0) {

			var winHeight = $('body').height();
			var footerHeight = $('#site-footer').outerHeight();
			var boxHeight = $('.floating-container').outerHeight();
			var noteHeight = ($('note').length > 0) ? $('.note').outerHeight(true) : 25;
			var bottomOfHeader = $('#site-header').height();
			var floatingTop = $('.floating-container').offset().top;
			var initFloatingTop = $('form.application-form').offset().top;

			var stopPoint = (winHeight) - (footerHeight + boxHeight);

			if ($(window).width() >= 1024) {

				if ($(window).scrollTop() >= stopPoint) {

					$('.floating-container').css({ position: 'absolute', top: 'auto', bottom: noteHeight });

				} else if ($(window).scrollTop() >= (initFloatingTop - bottomOfHeader)) {

					$('.floating-container').css({ position: 'fixed', top: 113 });

				} else {

					$('.floating-container').css({ position: 'absolute', top: 0 });

				}

			} else {

				$('.floating-container').css({ position: 'static', top: 'auto', bottom: 'auto' });

			}
		}

	}

	var __applicationHelpers = function () {

		__mobileLevelChanger();

		$('body').on('click', '.coverExpand', function (e) {
			const $this = $(this);
			const $coverInfo = $this.parent().parent().parent().next();

			// Close all open tabs and reset open/close icon
			$('.coverExpand').not($this).removeClass('is-active');
			$('.js-tab-coverInfo').not($coverInfo).removeClass('active');

			// Open tab
			$coverInfo.toggleClass('active');

			// Toggle open/close icon
			if ($this.hasClass('is-active')) {
				$this.removeClass('is-active');
			} else {
				$this.addClass('is-active');
			};
		});

		$('body').on('hover', '.pop-out-link', function (e) {
			$(this).next().toggleClass('active');
		}, function (e) {
			$(this).next().toggleClass('active');
		})

		$('body').on('click', '.expander-box.can-expand h2', function (e) {
			e.preventDefault();
			$(this).children('a').toggleClass('active');
			$(this).next().toggleClass('active');
		});

	}

	var __mobileLevelChanger = function () {

		$('body').on('click', '.tab-level-link', function (e) {

			e.preventDefault();
			var level = $(this).attr('data-level');
			$('.tab-level-info').removeClass('active');
			$('.tab-level-' + level).addClass('active');
			$('.tab-level-link').removeClass('active');

			$('.tab-level-link').each(function (i) {
				if ($(this).attr('data-level') == level) {
					$(this).addClass('active');
				}
			});

		});
	}

	var __searchTakeover = function () {

		$('.search-link, .search-link-inline').click(function (e) {
			e.preventDefault();
			if ($('#search-takeover').length > 0) {
				$('#search-takeover').addClass('active');
				$('body').addClass('takenover');
				$('#s').focus();
			}
		});

		$('#search-takeover .close-takeover').click(function (e) {
			e.preventDefault();
			$('#search-takeover').removeClass('active');
			$('body').removeClass('takenover');
		});

		$('#search-takeover .pop-questions a').click(function (e) {
			$('#search-takeover').removeClass('active');
			$('body').removeClass('takenover');
		});
	}

	var __accordion = function () {

		$('.accordion__step h3').click(function (e) {
			var item = $(this);
			//$('.accordion-step h3').removeClass('open');
			$(this).parent().siblings().children().next().slideUp(250);
			$(this).parent().siblings().find('h3').removeClass('open');

			$(this).toggleClass('open');
			$(this).next().slideToggle(300, function () {
				var top = item.offset().top;
				var scrlPoint = top - ($(window).height() / 4);
				window.scrollTo(0, scrlPoint);
			});
		});
	}

	var __accordionOpener = function () {

		var tar = window.location.hash;

		if (tar && $(tar).length > 0) {

			$(tar + ' h3').click();

			var headerHeight = $('#site-header').height();
			var oldTop = $(window).scrollTop();

			var newTop = oldTop - headerHeight;

			window.scrollTo(0, newTop);

		}
	}

	var __slidetoggle = function () {
		$('.feed-filters h4').click(function () {
			var type = $(this).data('type');
			$('.feed-filter-list[data-type=' + type + ']').slideToggle("slow");
		});

	}

	var __bannerSlider = function () {

		$(".banner-multi").slick({
			autoplay: true,
			autoplaySpeed: 5000,
		});

		$(".banner-customer-quote").slick({
			autoplay: true,
			autoplaySpeed: 7000,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true
		});

		var stHeight = $('.slick-track').height();
		$('.slick-slide').css('min-height', stHeight + 'px');

	}

	var __fixedNavigation = function (lastScroll) {
		let scrollTop = $(window).scrollTop();

		if (scrollTop > 0) {
			$('#site-header').addClass('fixed');
		} else {
			$('#site-header').removeClass('fixed');
		}
	}

	var __toggleNavBasedOnScroll = function () {
		var lastScrollTop = 0;
		var mainMenu = $('#main-nav');

		$(window).on('scroll', function(event){
			if (window.matchMedia('(min-width: 1023px)').matches){
				var scrollTop = $(this).scrollTop();

				if (scrollTop > lastScrollTop){
					mainMenu.slideUp();
				} else {
					mainMenu.slideDown();
				}

				lastScrollTop = scrollTop;
			} else {
				if  (!(mainMenu).is(':visible')) {
					mainMenu.show();
				}
			}
		});
	}

	var __contactFormReveal = function () {

		var selector = $('.form-selector');

		if (selector.length > 0) {
			$('.form-link a').click(function (e) {
				$('.form-link').removeClass('active');
				$('.form-link a').removeClass('active');
				var target = $(this).data('target');
				$(this).addClass('active');
				$(this).parent().addClass('active');
				$('.' + target).toggleClass('active').siblings().removeClass('active');

				if (target == 'tab-personal') {
					$('.form-selector h2').html('You are making a personal enquiry');
				} else if (target == 'tab-business') {
					$('.form-selector h2').html('You are making a business enquiry');
				} else if (target == 'tab-existing') {
					$('.form-selector h2').html('You are making an existing customer enquiry');
				}

			});
		}

	}

	var __infiniteNewsPosts = function (ele, loading) {

		if ($(ele).length > 0) {

			var winHeight = $(window).height();

			$(window).scroll(function () {
				var feedTop = $(ele).position().top,
					feedHeight = $(ele).outerHeight(),
					combined = (feedTop + feedHeight),
					triggerPoint = (combined - winHeight) + 30,
					winPos = $(window).scrollTop();

				if (winPos >= triggerPoint) {

					if ($(loading + '.loading').length == 0 && $(loading).length > 0) {
						$(loading).addClass('loading');
						var page = parseInt($(loading).attr("data-page")),
							cat = $(loading).data('category'),
							type = $(loading).data('type');

						var params = {
							action: 'sovereign_latest_posts',
							paged: page,
							cat: cat,
							type: type
						}

						$.get('/wp/wp-admin/admin-ajax.php', params, function (response) {
							var data = $.parseJSON(response);

							if (data.response == true) {
								var $feed = $(ele);
								$feed.append(data.html);

								var work = parseInt($(loading).attr("data-page"));
								var workadd = (work + 1);

								$(loading).attr("data-page", workadd);
								$(loading).removeClass('loading');
								if (data.amount < 6) {
									$(loading).remove();
								}
							} else {
								$(loading).remove();
							}
						});
					}

				}
			});
		};

	}

	String.prototype.decodeHTML = function () { return $("<div>", { html: "" + this }).html(); };

	var __inViewTrigger = function (element, elementOffset) {

		var winTop = $(window).scrollTop();
		var winHeight = $(window).height();
		var inViewMark = winTop + winHeight;

		if ($(element).length > 0) {

			$(element).each(function (i, item) {

				var itemTop = $(element).offset().top;
				var offset = ($(element).outerHeight() / elementOffset);

				if (inViewMark > (itemTop + offset)) {
					$(element).addClass('inview');
				}
			});
		}


		$(window).scroll(function (e) {

			var winTop = $(window).scrollTop();
			var winHeight = $(window).height();

			var inViewMark = winTop + winHeight;

			if ($(element).length > 0) {

				$(element).each(function (i, item) {

					var itemTop = $(element).offset().top;
					var offset = ($(element).outerHeight() / elementOffset);

					if (inViewMark > (itemTop + offset)) {
						$(element).addClass('inview');
					}
				});
			}
		});

	}

	var __mobilemenu = function () {

		$(".mobile-menu").click(function () {
			if ($(".mobile-menu").hasClass('active')) {
				$('.page-wrap').removeClass("showmenu");
				$(".mobile-menu").removeClass('active');
			} else {
				$('.page-wrap').addClass("showmenu");
				$(".mobile-menu").addClass('active');
			}
		});

		// Mobile menu - sub-menus
		// Hide all .sub-menus
		//$('#main-nav .sub-menu').hide();
		// Append toggle span
		$('#main-nav .top-menu > .menu-item-has-children').append('<span class="toggle-open">Open</span>');
		$('#main-nav .top-menu > .menu-item-has-children > .toggle-open').click(function (e) {
			// If li does not have .open class
			if (!$(this).parent().hasClass('open')) {
				$('#main-nav .menu-item-has-children .sub-menu').slideUp(); // Close any open .sub-menu
				$('#main-nav .menu-item-has-children').removeClass('open'); // Remove .open from all other li

				$(this).parent().addClass('open'); // Add .open to current link parent li
				$('#main-nav .menu-item-has-children.open > .sub-menu').slideDown();
				e.preventDefault(); // Prevent following link
			} else {
				$(this).parent().find('.sub-menu').slideUp();
				$(this).parent().removeClass('open');
			}
		});
		// Sub-sub-menus
		// Append toggle span
		$('#main-nav .sub-menu > .menu-item-has-children').append('<span class="toggle-open">Open</span>');
		$('#main-nav .sub-menu > .menu-item-has-children > .toggle-open').click(function (e) {
			// If li does not have .open class
			if (!$(this).parent().hasClass('open')) {
				$('#main-nav .menu-item-has-children .sub-menu .sub-menu').slideUp(); // Close any open .sub-menu
				$('#main-nav .menu-item-has-children .sub-menu li').removeClass('open'); // Remove .open from all other li

				$(this).parent().addClass('open'); // Add .open to current link parent li
				$('#main-nav .sub-menu .menu-item-has-children.open > .sub-menu').slideDown();
				e.preventDefault(); // Prevent following link
			} else {
				$(this).parent().find('.sub-menu').slideUp();
				$(this).parent().removeClass('open');
			}
		});

	}

	var __aboutnav = function () {
		$('#aboutNav li').click(function () {
			var scrollto = $(this).data('type');
			$('html, body').animate({
				scrollTop: $('div[data-type="' + scrollto + '"]').offset().top - 250
			}, 1500);
		});
	}

	var __onScrollEvents = function () {
		__fixedNavigation();
	}

	var __resizeWindowEvents = function () {
		__floatingSide();
	}

	var __youtubeiframes = function() {

		jQuery('.text-block iframe[src^="https://www.youtube.com/embed/"]').wrap('<div class="iframe-wrapper" />');

	}

	function getDOBYearRange()
	{
		var top_year = moment().subtract(18, 'years').format('Y'),
			bottom_year = moment().subtract(111, 'years').format('Y'),
			years = [],
			year = 0;


		for (var i = top_year; i >= bottom_year; i--) {
			years[year] = i;
			year++;
		}

		return years;
	}

	if ($('.js-time-field').length){

		$('.js-time-field').each((index,field) => {

			const select = $(field).find('select');

			if ($(field).hasClass('js-time-field--day')){

				for (let index = 1; index <= 31; index++) {
					select.append(`<option value="${index}">${index}</option>`)
				}

			} else if ($(field).hasClass('js-time-field--month')){

				for (let index = 1; index <= 12; index++) {
					select.append(`<option value="${index}">${index}</option>`)
				}
			}
			else if ($(field).hasClass('js-time-field--year')){

				const years = getDOBYearRange();

				for (let index = 0; index < years.length; index++) {
					select.append(`<option value="${years[index]}">${years[index]}</option>`)
				}
			}
		});
	}

	if($('.js-message-bar').length) {
		$('.js-message-bar__dismiss').on('click', function() {
			$(this).parent().hide();
			Cookies.set('hide_message_bar', true)
		});
	};

	if($('.js-logos__slider').length){
		const logosSlider = new Swiper( '.js-logos__slider', {
			slidesPerView: 2,
			spaceBetween: 20,
			autoplay: $('.js-logos').data('autoplay') ? {
				delay: 2000,
			}: false,
			grabCursor: true,
			loop: true,
			watchOverflow: true,
			navigation: {
				nextEl: '.js-logos .swiper-button-next',
				prevEl: '.js-logos .swiper-button-prev',
			},
			breakpoints: {
				768: {
					slidesPerView: 5,
					spaceBetween: 40
				}
			}
		});
	}

	if($('.js-banner-logos__slider').length){
		const logosSlider = new Swiper( '.js-banner-logos__slider', {
			slidesPerView: 2,
			slidesPerGroup: 2,
			spaceBetween: 20,
			autoplay: $('.js-banner-logos').data('autoplay') ? {
				delay: 4000,
			}: false,
			grabCursor: true,
			loop: true,
			watchOverflow: true,
			navigation: {
				nextEl: '.js-banner-logos .swiper-button-next',
				prevEl: '.js-banner-logos .swiper-button-prev',
			},
			breakpoints: {
				768: {
					slidesPerView: 5,
					slidesPerGroup: 5,
				}
			}
		});
	}

	// End jQuery wrapper
})(jQuery);

String.prototype.replaceAll = function (search, replacement) {
	var target = this;
	return target.replace(new RegExp(search, 'g'), replacement);
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-col summary-large-text" }, [
    _vm.declaration !== ""
      ? _c("fieldset", { staticClass: "formInner declaration-summary" }, [
          _c("legend", [_vm._v("Declaration")]),
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.declaration) } }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.dpa !== ""
      ? _c("fieldset", { staticClass: "formInner dpa-summary" }, [
          _c("legend", [_vm._v("Data Protection Act")]),
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.dpa) } }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.marketing !== ""
      ? _c("fieldset", { staticClass: "formInner marketing-summary" }, [
          _c("legend", [_vm._v("Your Marketing Contact Preferences")]),
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.marketing) } }),
          _vm._v(" "),
          _c("div", { staticClass: "marketing-options" }, [
            _c("div", { staticClass: "checkbox-container" }, [
              _c(
                "label",
                {
                  staticClass: "inline-label",
                  attrs: { for: "marketingPost" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.application.marketing.post,
                        expression: "application.marketing.post",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "marketingPost",
                      name: "marketingPost",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.application.marketing.post)
                        ? _vm._i(_vm.application.marketing.post, null) > -1
                        : _vm.application.marketing.post,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.application.marketing.post,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.application.marketing,
                                "post",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.application.marketing,
                                "post",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.application.marketing, "post", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Post")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox-container" }, [
              _c(
                "label",
                {
                  staticClass: "inline-label",
                  attrs: { for: "marketingPhone" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.application.marketing.phone,
                        expression: "application.marketing.phone",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "marketingPhone",
                      name: "marketingPhone",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.application.marketing.phone)
                        ? _vm._i(_vm.application.marketing.phone, null) > -1
                        : _vm.application.marketing.phone,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.application.marketing.phone,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.application.marketing,
                                "phone",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.application.marketing,
                                "phone",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.application.marketing, "phone", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Phone")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox-container" }, [
              _c(
                "label",
                {
                  staticClass: "inline-label",
                  attrs: { for: "marketingEmail" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.application.marketing.email,
                        expression: "application.marketing.email",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "marketingEmail",
                      name: "marketingEmail",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.application.marketing.email)
                        ? _vm._i(_vm.application.marketing.email, null) > -1
                        : _vm.application.marketing.email,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.application.marketing.email,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.application.marketing,
                                "email",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.application.marketing,
                                "email",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.application.marketing, "email", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Email")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox-container" }, [
              _c(
                "label",
                {
                  staticClass: "inline-label",
                  attrs: { for: "marketingText" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.application.marketing.text,
                        expression: "application.marketing.text",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "marketingText",
                      name: "marketingText",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.application.marketing.text)
                        ? _vm._i(_vm.application.marketing.text, null) > -1
                        : _vm.application.marketing.text,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.application.marketing.text,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.application.marketing,
                                "text",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.application.marketing,
                                "text",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.application.marketing, "text", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Text")]),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.confirmation !== ""
      ? _c("fieldset", { staticClass: "formInner confirmation-summary" }, [
          _c("legend", [_vm._v("Confirmation")]),
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.confirmation) } }),
          _vm._v(" "),
          _c("div", { staticClass: "checkbox-container" }, [
            _c(
              "label",
              { staticClass: "inline-label", attrs: { for: "agreement" } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.applicant_declaration,
                      expression: "applicant_declaration",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    id: "agreement",
                    name: "agreement",
                    "data-cy": "summary-agreement",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.applicant_declaration)
                      ? _vm._i(_vm.applicant_declaration, null) > -1
                      : _vm.applicant_declaration,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.applicant_declaration,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.applicant_declaration = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.applicant_declaration = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.applicant_declaration = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "I have read and agree to the policy terms and conditions and declaration above."
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.errors.confirmation.valid === false
      ? _c("span", {
          staticClass: "help-block form-error",
          attrs: { "data-cy": "summary-agreement-error" },
          domProps: { innerHTML: _vm._s(_vm.errors.confirmation.message) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
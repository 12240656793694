var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-indicator" }, [
    _c(
      "a",
      {
        class: { active: _vm.is_step_1 },
        attrs: { "data-cy": "step-indicator-1" },
        on: {
          click: function ($event) {
            return _vm.updateCurrentStage("stage-one")
          },
        },
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        class: { active: _vm.is_step_2, disabled: !_vm.is_stage_one_valid },
        attrs: { "data-cy": "step-indicator-2" },
        on: {
          click: function ($event) {
            return _vm.updateCurrentStage("stage-two")
          },
        },
      },
      [_vm._m(1)]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        class: { active: _vm.is_step_3, disabled: !_vm.is_stage_two_valid },
        attrs: { "data-cy": "step-indicator-3" },
        on: {
          click: function ($event) {
            return _vm.updateCurrentStage("stage-three")
          },
        },
      },
      [_vm._m(2)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step" }, [
      _c("span", { staticClass: "stepNum" }, [_vm._v("1")]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("Cover Details")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step" }, [
      _c("span", { staticClass: "stepNum" }, [_vm._v("2")]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("Direct Debit Details")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "step" }, [
      _c("span", { staticClass: "stepNum" }, [_vm._v("3")]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("Check Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
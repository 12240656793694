var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "level-selector-wrap" }, [
    _c("div", { staticClass: "level-selector-wrap__header" }, [
      _c("p", [_vm._v("Cover level")]),
      _vm._v(" "),
      _c("button", {
        staticClass: "compare-button",
        class: { "compare-button--open": _vm.view_full_table },
        domProps: { innerHTML: _vm._s(_vm.compare_button_text) },
        on: {
          click: function ($event) {
            return _vm.toggleCompareLevels()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "wrap-x-slide" },
      [
        !_vm.view_full_table
          ? _c(
              "div",
              { staticClass: "level-selector" },
              [
                _vm._l([1, 2, 3, 4, 5], function (level) {
                  return [
                    _c(
                      "label",
                      {
                        key: "your-level-" + level,
                        class: { active: _vm.application.cover_level == level },
                        on: {
                          click: function ($event) {
                            return _vm.updateCoverLevel(level)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "level" }, [
                          _vm._v("Level " + _vm._s(level)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "prices-wrap" }, [
                          _c("span", { staticClass: "amount" }, [
                            _vm._v("£" + _vm._s(_vm.getPriceLevelLabel(level))),
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "pp",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.price_level_label_per_adult
                              ),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.application.cover_level,
                                expression: "application.cover_level",
                              },
                            ],
                            staticClass: "level-select your-level-select",
                            attrs: {
                              "data-validation": "required",
                              "data-validation-error-msg":
                                "Please select a level of cover",
                              type: "radio",
                              name: "main[level]",
                            },
                            domProps: {
                              value: level,
                              checked: _vm._q(
                                _vm.application.cover_level,
                                level
                              ),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.application,
                                  "cover_level",
                                  level
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "checked" }),
                        ]),
                      ]
                    ),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.view_full_table
          ? [_c("payroll-table", { attrs: { benefits: _vm.benefits } })]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
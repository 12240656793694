<template>
    <fieldset class="main-col edit-child-form" v-if="child !== null">

        <div class="formInner no-bottom">
            <a class="editChild editBox" :data-child-id="index" :id="'childLink-'+index" :class="{ 'active' : this.editing }" @click="toggleUpdateForm()">
                <p v-html="toggle_title"></p>
            </a>

            <div class="childForm" :class="{ 'editing' : this.editing, 'hidden' : !this.editing }">

                <div class="warnings premium-tax">
                    <p class="has-i"><i class="fa fa-info"></i>Add up to four dependent children up to the age of 18 who live with you below.</p>

                    <div class="childConsent">
                        <p>As a parent or legal guardian of the dependent child, you confirm you have the authority to share their personal data on their behalf.</p>
                    </div>
                </div>

				<div class="input-group multiple">
                    <div class="input-group input-title">
                        <label :for="'childTitle-'+index"><sup>*</sup>Title</label>
                        <div class="select-wrapper">
                            <select :name="'child['+index+'][title]'" v-model="child_title" :id="'childTitle-'+index" @blur="validateTitle()" data-cy="edit-child-title">
                                <option disabled value="">Select</option>
                                <option value="Miss">Miss</option>
                                <option value="Master">Master</option>
                            </select>
                        </div>

                        <span class="help-block form-error" v-if="valid_title !== null && valid_title.valid === false" v-html="valid_title.message" data-cy="edit-child-title"></span>
                    </div>

                    <div class="input-box input-firstname">
                        <label :for="'childFirstName-'+index"><sup>*</sup>First name(s)</label>
                        <input type="text" :name="'child['+index+'][first]'" id="'childFirstName-'+index" v-model="child_first_name" @blur="validateFirstName()" data-cy="edit-child-firstname"/>

                        <span class="help-block form-error" v-if="valid_first_name !== null && valid_first_name.valid === false" v-html="valid_first_name.message" data-cy="edit-child-firstname-error"></span>
                    </div>

                    <div class="input-box input-lastname">
                        <label :for="'childLastName-'+index"><sup>*</sup>Surname</label>
                        <input type="text" :name="'child['+index+'][lastname]'" id="'childLastName-'+index" v-model="child_last_name" @blur="validateLastName()" data-cy="edit-child-lastname"/>

                        <span class="help-block form-error" v-if="valid_last_name !== null && valid_last_name.valid === false" v-html="valid_last_name.message" data-cy="edit-child-lastname-error"></span>
                    </div>
                </div>

                <div class="dob-wrap">
                    <label :for="'childDOB-'+index"><sup>*</sup>Date of birth</label>
                    <div class="input-group multiple">
                        <div class="select-wrapper sm">
                            <select :name="'child['+index+'][date_of_birth][day]'" v-model="child_dob_day" :class="{ 'has-error' : valid_dob.valid === false }" data-cy="edit-child-dob-day">
                                <option disabled value="">DD</option>
                                <option v-for="day in 31" :key="day">{{day}}</option>
                            </select>
                        </div>

                        <div class="select-wrapper sm">
                            <select :name="'child['+index+'][date_of_birth][month]'" v-model="child_dob_month" :class="{ 'has-error' : valid_dob.valid === false }" data-cy="edit-child-dob-month">
                                <option disabled value="">MM</option>
                                <option v-for="month in 12" :key="month">{{month}}</option>
                            </select>
                        </div>

                        <div class="select-wrapper sm">
                            <select :name="'child['+index+'][date_of_birth][year]'" v-model="child_dob_year" :class="{ 'has-error' : valid_dob.valid === false }" data-cy="edit-child-dob-year">
                                <option disabled value="">YYYY</option>
                                <option v-for="year in dob_year_range" :key="year">{{year}}</option>
                            </select>
                        </div>

                        <span class="help-block form-error" v-if="valid_dob.valid === false" v-html="valid_dob.message" data-cy="edit-child-dob-error"></span>
                    </div>
                </div>

                <div class="action-box">
                    <input type="button" value="Remove" class="btn btn-secondary" @click="removeChildApplicant()" />
                    <input type="button" value="Update" class="btn btn--primary" :disabled="!is_valid" @click="updateChildApplicant()" />
				</div>
			</div>
		</div>
	</fieldset>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        props:[ 'child', 'index' ],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            child_title: {
                get () {
                    return this.child.title;
                },
                set (value) {
                    this.child.title = this.sanitizeValue(value);
                }
            },
            child_first_name: {
                get () {
                    return this.child.first_name;
                },
                set (value) {
                    this.child.first_name = this.sanitizeValue(value);
                }
            },
            child_last_name: {
                get () {
                    return this.child.last_name;
                },
                set (value) {
                    this.child.last_name = this.sanitizeValue(value);
                }
            },
            child_dob_day: {
                get () {
                    return this.child.date_of_birth.day;
                },
                set (value) {
                    this.child.date_of_birth.day = this.sanitizeValue(value);
                }
            },
            child_dob_month: {
                get () {
                    return this.child.date_of_birth.month;
                },
                set (value) {
                    this.child.date_of_birth.month = this.sanitizeValue(value);
                }
            },
            child_dob_year: {
                get () {
                    return this.child.date_of_birth.year;
                },
                set (value) {
                    this.child.date_of_birth.year = this.sanitizeValue(value);
                }
            },
            dob_year_range: function() {
                var top_year = moment().format('Y'),
                    bottom_year = moment().subtract(18, 'years').format('Y'),
                    years = [],
                    year = 0;


                for(var i = top_year; i >= bottom_year; i--){
                    years[year] = i;
                    year++;
                }

                return years;
            },
            is_valid: function() {
                let vm = this,
                    valid = true;

                if( vm.valid_title === null || vm.valid_title.valid === false ) valid = false;
                if( vm.valid_first_name === null || vm.valid_first_name.valid === false ) valid = false;
                if( vm.valid_last_name === null || vm.valid_last_name.valid === false ) valid = false;
                if( vm.valid_dob.valid === false || vm.valid_dob.valid === null ) valid = false;

                return valid;
            },
            toggle_title: function(){
                let vm = this,
                    title = "",
                    name = "",
                    dob = "",
                    level = vm.getMainLevel();

                name = vm.cleanName( vm.child.title, vm.child.first_name, vm.child.last_name )
                dob = vm.child_dob_day + "/" + vm.child_dob_month + "/" + vm.child_dob_year;

                title = name +  " - " + dob + " - FREE";

                return title;
            },
            valid_dob: function() {
                let vm = this;

                var valid_dob = vm.checkValidDateOfBirth( vm.child.date_of_birth, 'child' );

                return valid_dob;
            }
        },
        created() {
        },
        mounted() {
            let vm = this;
            vm.validateTitle();
            vm.validateFirstName();
            vm.validateLastName();
        },
        methods: {
            removeChildApplicant: function(){
                let vm = this;
                vm.$store.dispatch( 'removeChildApplicant', vm.index );
            },
            toggleUpdateForm: function(){
                let vm = this;
                vm.editing = !vm.editing;
            },
            updateChildApplicant: function(){
                let vm = this;
                vm.$store.dispatch( 'updateChildApplicant', [ vm.index, vm.child ] );
                vm.editing = false;
            },
            validateTitle() {
                let vm = this;
                vm.valid_title = vm.checkValidTitle( vm.child.title, 'child' );
            },
            validateFirstName() {
                let vm = this;
                vm.valid_first_name = vm.checkValidFirstName( vm.child.first_name, 'child' );
            },
            validateLastName() {
                let vm = this;
                vm.valid_last_name = vm.checkValidLastName( vm.child.last_name, 'child' );
            }
        },
        data () {
            return {
                editing: false,
                valid_title: null,
                valid_first_name: null,
                valid_last_name: null
            }
        }
    }
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-col primary-form" }, [
    _c("fieldset", { staticClass: "main-details-form" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "formInner" }, [
        _vm.errors.cover_level.valid === false
          ? _c("span", {
              staticClass: "help-block form-error",
              domProps: { innerHTML: _vm._s(_vm.errors.cover_level.message) },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("legend", [_vm._v("Your Details")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group multiple" }, [
          _c("div", { staticClass: "input-group input-title" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "select-wrapper" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.applicant_title,
                      expression: "applicant_title",
                    },
                  ],
                  class: { "has-error": _vm.errors.title.valid === false },
                  attrs: {
                    id: "mainTitle",
                    name: "main[title]",
                    "data-cy": "main-title",
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.validateTitle()
                    },
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.applicant_title = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { disabled: "", value: "" } }, [
                    _vm._v("Select"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Mr" } }, [_vm._v("Mr")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Mrs" } }, [_vm._v("Mrs")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Miss" } }, [_vm._v("Miss")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Ms" } }, [_vm._v("Ms")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Dr" } }, [_vm._v("Dr")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "n/a" } }, [
                    _vm._v("Prefer not to say"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.errors.title.valid === false
              ? _c("span", {
                  staticClass: "help-block form-error",
                  attrs: { "data-cy": "main-title-error" },
                  domProps: { innerHTML: _vm._s(_vm.errors.title.message) },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group input-firstname" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.applicant_first_name,
                  expression: "applicant_first_name",
                },
              ],
              class: { "has-error": _vm.errors.first_name.valid === false },
              attrs: {
                type: "text",
                name: "main[first_name]",
                id: "mainFirst",
                "data-cy": "main-firstname",
              },
              domProps: { value: _vm.applicant_first_name },
              on: {
                blur: function ($event) {
                  return _vm.validateFirstName()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.applicant_first_name = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.first_name.valid === false
              ? _c("span", {
                  staticClass: "help-block form-error",
                  attrs: { "data-cy": "main-firstname-error" },
                  domProps: {
                    innerHTML: _vm._s(_vm.errors.first_name.message),
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group input-lastname" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.applicant_last_name,
                  expression: "applicant_last_name",
                },
              ],
              class: { "has-error": _vm.errors.last_name.valid === false },
              attrs: {
                type: "text",
                name: "main[last_name]",
                id: "mainLast",
                "data-cy": "main-lastname",
              },
              domProps: { value: _vm.applicant_last_name },
              on: {
                blur: function ($event) {
                  return _vm.validateLastName()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.applicant_last_name = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _vm.errors.last_name.valid === false
              ? _c("span", {
                  staticClass: "help-block form-error",
                  attrs: { "data-cy": "main-lastname-error" },
                  domProps: { innerHTML: _vm._s(_vm.errors.last_name.message) },
                })
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dob-wrap" }, [
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "input-group multiple" }, [
            _c("div", { staticClass: "select-wrapper sm" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.applicant_dob_day,
                      expression: "applicant_dob_day",
                    },
                  ],
                  class: {
                    "has-error": _vm.errors.date_of_birth.valid === false,
                  },
                  attrs: {
                    id: "mainDobDay",
                    name: "main[date_of_birth][day]",
                    "data-cy": "main-dob-day",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.applicant_dob_day = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.updateDOB("day")
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { disabled: "", value: "" } }, [
                    _vm._v("DD"),
                  ]),
                  _vm._v(" "),
                  _vm._l(31, function (day) {
                    return _c(
                      "option",
                      { key: day, domProps: { value: day } },
                      [_vm._v(_vm._s(day))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "select-wrapper sm" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.applicant_dob_month,
                      expression: "applicant_dob_month",
                    },
                  ],
                  class: {
                    "has-error": _vm.errors.date_of_birth.valid === false,
                  },
                  attrs: {
                    id: "mainDobMonth",
                    name: "main[date_of_birth][month]",
                    "data-cy": "main-dob-month",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.applicant_dob_month = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.updateDOB("month")
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { disabled: "", value: "" } }, [
                    _vm._v("MM"),
                  ]),
                  _vm._v(" "),
                  _vm._l(12, function (month) {
                    return _c(
                      "option",
                      { key: month, domProps: { value: month } },
                      [_vm._v(_vm._s(month))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "select-wrapper sm" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.applicant_dob_year,
                      expression: "applicant_dob_year",
                    },
                  ],
                  class: {
                    "has-error": _vm.errors.date_of_birth.valid === false,
                  },
                  attrs: {
                    id: "mainDobYear",
                    name: "main[date_of_birth][year]",
                    "data-cy": "main-dob-year",
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.applicant_dob_year = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.updateDOB("year")
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { disabled: "", value: "" } }, [
                    _vm._v("YYYY"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.dob_year_range, function (year) {
                    return _c(
                      "option",
                      { key: year, domProps: { value: year } },
                      [_vm._v(_vm._s(year))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm.errors.date_of_birth.valid === false
              ? _c("span", {
                  staticClass: "help-block form-error",
                  attrs: { "data-cy": "main-dob-error" },
                  domProps: {
                    innerHTML: _vm._s(_vm.errors.date_of_birth.message),
                  },
                })
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("fieldset", { staticClass: "contact-details-form" }, [
      _c("div", { staticClass: "formInner" }, [
        _c("legend", [_vm._v("Contact Details")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _vm._m(5),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.applicant_email_address,
                expression: "applicant_email_address",
              },
            ],
            class: { "has-error": _vm.errors.email_address.valid === false },
            attrs: {
              type: "text",
              name: "main[email_address]",
              id: "mainEmail",
              "data-cy": "main-email",
            },
            domProps: { value: _vm.applicant_email_address },
            on: {
              blur: function ($event) {
                return _vm.validateEmailAddress()
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.applicant_email_address = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.email_address.valid === false
            ? _c("span", {
                staticClass: "help-block form-error",
                attrs: { "data-cy": "main-email-error" },
                domProps: {
                  innerHTML: _vm._s(_vm.errors.email_address.message),
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _vm._m(6),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.applicant_phone_number,
                expression: "applicant_phone_number",
              },
            ],
            class: { "has-error": _vm.errors.phone_number.valid === false },
            attrs: {
              type: "text",
              name: "main[phone_number]",
              id: "mainPhone",
              "data-cy": "main-phone",
            },
            domProps: { value: _vm.applicant_phone_number },
            on: {
              blur: function ($event) {
                return _vm.validatePhoneNumber()
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.applicant_phone_number = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.phone_number.valid === false
            ? _c("span", {
                staticClass: "help-block form-error",
                attrs: { "data-cy": "main-phone-error" },
                domProps: {
                  innerHTML: _vm._s(_vm.errors.phone_number.message),
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "postcode-wrap" }, [
          _vm._m(7),
          _vm._v(" "),
          _c("div", { staticClass: "input-group multiple" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search_postcode,
                  expression: "search_postcode",
                },
              ],
              class: {
                "has-error":
                  _vm.valid_search_postcode !== null &&
                  _vm.valid_search_postcode.valid === false,
              },
              attrs: {
                type: "text",
                id: "searchPostcode",
                "data-cy": "main-postcode-search",
              },
              domProps: { value: _vm.search_postcode },
              on: {
                blur: function ($event) {
                  return _vm.cleanAddress()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.search_postcode = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "housePicker" }, [
              _c("input", {
                staticClass: "btn btn--primary",
                attrs: {
                  type: "button",
                  value: "Find Address",
                  disabled:
                    _vm.search_postcode == null || _vm.search_postcode == "",
                  "data-cy": "main-postcode-search-button",
                },
                on: {
                  click: function ($event) {
                    return _vm.lookupAddress()
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "reveal-address reveal-address--main",
                on: {
                  click: function ($event) {
                    return _vm.revealAddress()
                  },
                },
              },
              [_vm._v("Enter your address manually")]
            ),
          ]),
          _vm._v(" "),
          _vm.valid_search_postcode !== null &&
          _vm.valid_search_postcode.valid === false
            ? _c("span", {
                staticClass: "help-block form-error",
                attrs: { "data-cy": "main-postcode-search-error" },
                domProps: {
                  innerHTML: _vm._s(_vm.valid_search_postcode.message),
                },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "hidden", attrs: { id: "mainAddressHide" } }, [
          _c("label", { attrs: { for: "addressSelect" } }, [
            _vm._v("Select your address"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "select-wrapper" }, [
            _c(
              "select",
              {
                attrs: {
                  name: "addressSelect",
                  id: "mainAddressSelect",
                  "data-cy": "main-address-select",
                },
                on: {
                  change: function ($event) {
                    return _vm.updateAddress()
                  },
                },
              },
              [_c("option")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: { hidden: _vm.applicant_address_line_1 === null },
            attrs: { id: "mainAddressLines" },
          },
          [
            _c("div", { staticClass: "input-group" }, [
              _vm._m(8),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.applicant_address_line_1,
                    expression: "applicant_address_line_1",
                  },
                ],
                class: {
                  "has-error": _vm.errors.address_line_1.valid === false,
                },
                attrs: {
                  type: "text",
                  name: "main[addressLine1]",
                  id: "mainAddressLine1",
                  "data-cy": "main-address-1",
                },
                domProps: { value: _vm.applicant_address_line_1 },
                on: {
                  blur: function ($event) {
                    return _vm.validateAddressLine1()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.applicant_address_line_1 = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.address_line_1.valid === false
                ? _c("span", {
                    staticClass: "help-block form-error",
                    attrs: { "data-cy": "main-address-1-error" },
                    domProps: {
                      innerHTML: _vm._s(_vm.errors.address_line_1.message),
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              _vm._m(9),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.applicant_address_line_2,
                    expression: "applicant_address_line_2",
                  },
                ],
                class: {
                  "has-error": _vm.errors.address_line_2.valid === false,
                },
                attrs: {
                  type: "text",
                  name: "main[addressLine2]",
                  id: "mainAddressLine2",
                  "data-cy": "main-address-2",
                },
                domProps: { value: _vm.applicant_address_line_2 },
                on: {
                  blur: function ($event) {
                    return _vm.validateAddressLine2()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.applicant_address_line_2 = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.address_line_2.valid === false
                ? _c("span", {
                    staticClass: "help-block form-error",
                    attrs: { "data-cy": "main-address-2-error" },
                    domProps: {
                      innerHTML: _vm._s(_vm.errors.address_line_2.message),
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("label", { attrs: { for: "mainAddressLine3" } }, [
              _vm._v("Address line 3"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.applicant_address_line_3,
                  expression: "applicant_address_line_3",
                },
              ],
              attrs: {
                type: "text",
                name: "main[addressLine3]",
                id: "mainAddressLine3",
                "data-cy": "main-address-3",
              },
              domProps: { value: _vm.applicant_address_line_3 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.applicant_address_line_3 = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "mainAddressLine4" } }, [
              _vm._v("Address line 4"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.applicant_address_line_4,
                  expression: "applicant_address_line_4",
                },
              ],
              attrs: {
                type: "text",
                name: "main[addressLine4]",
                id: "mainAddressLine4",
                "data-cy": "main-address-4",
              },
              domProps: { value: _vm.applicant_address_line_4 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.applicant_address_line_4 = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "postcode-wrap" }, [
              _vm._m(10),
              _vm._v(" "),
              _c("div", { staticClass: "input-group multiple" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.applicant_postcode,
                      expression: "applicant_postcode",
                    },
                  ],
                  class: { "has-error": _vm.errors.postcode.valid === false },
                  attrs: {
                    type: "text",
                    name: "main[postcode]",
                    id: "mainPostcode",
                    "data-cy": "main-address-postcode",
                  },
                  domProps: { value: _vm.applicant_postcode },
                  on: {
                    blur: function ($event) {
                      return _vm.validatePostcode()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.applicant_postcode = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.postcode.valid === false &&
                _vm.applicant_postcode == ""
                  ? _c("span", {
                      staticClass: "help-block form-error",
                      attrs: { "data-cy": "main-address-postcode-error" },
                      domProps: {
                        innerHTML: _vm._s(_vm.errors.postcode.message),
                      },
                    })
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("fieldset", { staticClass: "additional-details-form" }, [
      _c("div", { staticClass: "formInner" }, [
        _c("legend", [_vm._v("Additional Information")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("label", { attrs: { for: "promoCode" } }, [
            _vm._v(
              "Recommended by a friend? Please enter their policy number here so you both receive vouchers"
            ),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.applicant_promo_code,
                expression: "applicant_promo_code",
              },
            ],
            attrs: {
              type: "text",
              id: "promoCode",
              name: "promoCode",
              "data-cy": "main-promo-code",
            },
            domProps: { value: _vm.applicant_promo_code },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.applicant_promo_code = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("label", { attrs: { for: "offerCode" } }, [
            _vm._v(
              "Offer code (if you've received an offer code from us, please insert it here to claim your vouchers)"
            ),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.applicant_offer_code,
                expression: "applicant_offer_code",
              },
            ],
            attrs: {
              type: "text",
              id: "offerCode",
              name: "offerCode",
              "data-cy": "main-offer-code",
            },
            domProps: { value: _vm.applicant_offer_code },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.applicant_offer_code = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _vm._m(11),
          _vm._v(" "),
          _c("div", { staticClass: "select-wrapper" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.applicant_where_did_you_hear,
                    expression: "applicant_where_did_you_hear",
                  },
                ],
                class: {
                  "has-error": _vm.errors.where_did_you_hear.valid === false,
                },
                attrs: {
                  name: "main[referer]",
                  id: "mainReferer",
                  "data-cy": "main-referrer",
                },
                on: {
                  blur: function ($event) {
                    return _vm.validateWhereDidYouHear()
                  },
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.applicant_where_did_you_hear = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { disabled: "", value: "" } }, [
                  _vm._v("Please select"),
                ]),
                _vm._v(" "),
                _c("option", [_vm._v("Recommended by friend/family")]),
                _vm._v(" "),
                _c("option", [_vm._v("Social media")]),
                _vm._v(" "),
                _c("option", [_vm._v("Saw an online advert")]),
                _vm._v(" "),
                _c("option", [_vm._v("Previous customer")]),
                _vm._v(" "),
                _c("option", [_vm._v("Received a letter")]),
                _vm._v(" "),
                _c("option", [_vm._v("Other")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.errors.where_did_you_hear.valid === false
            ? _c("span", {
                staticClass: "help-block form-error",
                attrs: { "data-cy": "main-referrer-error" },
                domProps: {
                  innerHTML: _vm._s(_vm.errors.where_did_you_hear.message),
                },
              })
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "formInner premium-tax" }, [
      _c("p", { staticClass: "has-i" }, [
        _c("i", { staticClass: "fa fa-info" }),
        _vm._v("Premiums include insurance premium tax (IPT)."),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Please note: if you are applying for a cash plan on behalf of someone else or you are using someone else's bank details to pay for your policy, please either download, print and fill in an "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "/app/uploads/2023/12/@GAR_DD_Brochure_20231211.pdf",
              target: "_blank",
            },
          },
          [_vm._v("application form")]
        ),
        _vm._v(
          " or call us on 0800 678 5605 to apply (lines are open Monday to Thursday from 9am to 5pm, and Fridays from 9am to 4pm)."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "mainTitle" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Title"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "mainFirst" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("First name(s)"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "mainLast" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Surname"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "mainDob" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Date of birth"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "mainEmail" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Email"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "mainPhone" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Contact phone number"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "searchPostcode" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Postcode"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "mainAddressLine1" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Address line 1"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "mainAddressLine2" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Address line 2"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "mainPostcode" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Postcode"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "mainReferer" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("How did you hear about Sovereign Health Care?"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "application-form-wrap", class: { loading: _vm.loading } },
    [
      _vm.loading ? _c("span", { staticClass: "loading-spinner" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "application-stage application-stage--one" },
        [
          _c("application-step-indicator"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "application-stage__level-selector" },
            [_c("application-level-selector")],
            1
          ),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "application-form",
              attrs: { id: "appFormOne", method: "post", novalidate: "true" },
            },
            [
              _vm.show_errors && _vm.is_stage_one_valid === false
                ? _c("div", { staticClass: "main-col validation-error-col" }, [
                    _vm._m(0),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("application-form-primary"),
              _vm._v(" "),
              _vm._l(
                _vm.application.additional_policies.adults,
                function (adult, adult_key) {
                  return _c("application-form-edit-adults", {
                    key: "adult-" + adult_key,
                    attrs: { adult: adult, index: adult_key },
                  })
                }
              ),
              _vm._v(" "),
              _c("application-form-add-adults"),
              _vm._v(" "),
              _vm._l(
                _vm.application.additional_policies.children,
                function (child, child_key) {
                  return _c("application-form-edit-children", {
                    key: "child-" + child_key,
                    attrs: { child: child, index: child_key },
                  })
                }
              ),
              _vm._v(" "),
              _c("application-form-add-children"),
              _vm._v(" "),
              _c("application-important-notes"),
              _vm._v(" "),
              _c("application-summary-box"),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-error" }, [
      _c("p", [_vm._v("Please ensure all required fields are completed")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
export default {
    computed: {
        template_path() {
            return window.templatePath;
        },
        images_path() {
            return window.imagesPath;
        },
        is_stage_one_valid: function() {
            let vm = this,
                valid = true;

            if( vm.application.cover_level === null || !vm.errors.cover_level.valid ) valid = false;
            if( vm.application.first_name === null || !vm.errors.first_name.valid ) valid = false;
            if( vm.application.title === null || !vm.errors.title.valid ) valid = false;
            if( vm.application.last_name === null || !vm.errors.last_name.valid ) valid = false;
            if( vm.application.date_of_birth.day === '' || vm.application.date_of_birth.month === '' || vm.application.date_of_birth.year === ''
                || !vm.errors.date_of_birth.valid ) {
                    valid = false;
            }
            if( vm.application.address.line_1 === null || !vm.errors.address_line_1.valid ) valid = false;
            if( vm.application.address.line_2 === null || !vm.errors.address_line_2.valid ) valid = false;
            if( vm.application.address.postcode === null || !vm.errors.postcode.valid ) valid = false;
            if( vm.application.email_address === null || !vm.errors.email_address.valid ) valid = false;
            if( vm.application.phone_number === null || !vm.errors.phone_number.valid ) valid = false;
            if( vm.application.where_did_you_hear === null || !vm.errors.where_did_you_hear.valid ) valid = false;

            if( valid == false ) {
                vm.$store.dispatch( 'updateStage', [ 'step-2', false ] );
            }

            return valid;
        },
        is_stage_two_valid: function() {
            let vm = this,
                valid = true;

            if( vm.application.payment_confirmation === null || !vm.errors.payment_confirmation.valid ) valid = false;
            if( vm.application.account_holder_name === null || !vm.errors.account_holder_name.valid ) valid = false;
            if( vm.application.sort_code === null || !vm.errors.sort_code.valid ) valid = false;
            if( vm.application.account_number === null || !vm.errors.account_number.valid ) valid = false;

            if( valid == false ) {
                vm.$store.dispatch( 'updateStage', [ 'step-3', false ] );
            }

            return true;
        },
        is_stage_three_valid: function() {
            let vm = this,
                valid = true;

            if( !vm.application.confirmation || !this.is_stage_one_valid || !this.is_stage_two_valid ) {
                valid = false;
            }

            if( valid == false ) {
                vm.$store.dispatch( 'updateStage', [ 'confirmation', false ] );
            }

            return valid;
        },
        price_level_label_per_adult: function() {
            let vm = this;
            var label = '';

            label = 'Per adult per month';

            return label;
        },
        price_level_label_simple: function() {
            let vm = this;
            var label = '';

            label = 'Per month';

            return label;
        },
        price_level_label: function() {
            let vm = this;
            var label = '';

            label = 'Monthly premium (per adult)';

            return label;
        },
        total_application_cost: function() {
            let vm = this;
            var total = 0.00,
                cover_level = vm.application.cover_level;

            if( vm.prices ) {
                total = Number(vm.prices.ppm['level_'+cover_level]);

                if( Object.keys(vm.application.additional_policies.adults).length > 0 ){
                    for (var adultIndex in vm.application.additional_policies.adults) {

                        var adult = vm.application.additional_policies.adults[adultIndex];
                        if( adult.cover_level !== null ) {
                            total += Number(vm.prices.ppm['level_'+adult.cover_level]);
                        }
                    }
                }
            }

            return Number(total).toFixed(2);
        }
    },
    methods: {
        capitalizeFirstLetter: function( string ) {
            return string[0].toUpperCase() + string.slice(1);
        },
        checkValidTitle: function( title, type ) {
            let vm = this
            var valid = { valid: null, message: ''  };

            if( type == 'child' ) {
                if( title === null || title.trim() == '' ) {
                    valid.valid = false;
                    valid.message = vm.errors['title'].messages.empty_child;
                    return valid;
                }

            } else if( type == 'adult' ) {
                if( title === null || title.trim() == '' ) {
                    valid.valid = false;
                    valid.message = vm.errors['title'].messages.empty_adult;
                    return valid;
                }
            }

            return valid;
        },
        checkValidFirstName: function( first_name, type ) {
            let vm = this
            var valid = { valid: null, message: ''  };

            if( type == 'child' ) {
                if( first_name === null || first_name.trim() == '' ) {
                    valid.valid = false;
                    valid.message = vm.errors['first_name'].messages.empty_child;
                    return valid;
                }
            } else if( type == 'adult' ) {
                if( first_name === null || first_name.trim() == '' ) {
                    valid.valid = false;
                    valid.message = vm.errors['first_name'].messages.empty_adult;
                    return valid;
                }
            }

            return valid;
        },
        checkValidLastName: function( last_name, type ) {
            let vm = this
            var valid = { valid: null, message: ''  };

            if( type == 'child' ) {
                if( last_name === null || last_name.trim() == '' ) {
                    valid.valid = false;
                    valid.message = vm.errors['last_name'].messages.empty_child;
                    return valid;
                }

            } else if( type == 'adult' ) {
                if( last_name === null || last_name.trim() == '' ) {
                    valid.valid = false;
                    valid.message = vm.errors['last_name'].messages.empty_adult;
                    return valid;
                }
            }

            return valid;
        },
        checkValidEmail: function (email) {
            let vm = this;
            var regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

            if( email == '' || email == null ) {
                var valid = {
                    valid: true,
                    message: ''
                };
            } else if( vm.application.email_address == email ) {
                var valid = {
                    valid: false,
                    message: vm.errors['email_address'].messages.invalid_adult_duplicate
                };
            } else {
                var valid = {
                    valid: regex.test(email),
                    message: vm.errors['email_address'].messages.invalid_adult
                };
            }

            return valid;
        },
        checkValidDateOfBirth: function( date_of_birth, type ) {
            let vm = this
            var valid = { valid: null, message: ''  };

            var current_date = moment();
            var applicant_dob = moment(date_of_birth.day + '-' + date_of_birth.month + '-' + date_of_birth.year, 'DD-MM-YYYY');
            var age = current_date.diff(applicant_dob, 'years');

            var days_in_month = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

            // Adjust for leap years
            if( date_of_birth.year % 400 == 0 || ( date_of_birth.year % 100 != 0 && date_of_birth.year % 4 == 0 ) ) {
                days_in_month[1] = 29;
            }

            if( date_of_birth.day > days_in_month[ date_of_birth.month - 1 ] ) {
                valid.valid = false;
                valid.message = vm.errors['date_of_birth'].messages.not_date;
            } else if( type == 'child' ) {

                if( date_of_birth.day === null || date_of_birth.day.trim == ''
                    || date_of_birth.month === null || date_of_birth.month == ''
                    || date_of_birth.year === null || date_of_birth.year == '' ) {
                        valid.valid = false;
                        valid.message = vm.errors['date_of_birth'].messages.invalid_child;

                        return valid;
                }
                else if( age > 17 ) {
                    valid.valid = false;
                    valid.message = vm.errors['date_of_birth'].messages.too_old;
                } else if( current_date.diff(applicant_dob) < 0 ){
                    valid.valid = false;
                    valid.message = vm.errors['date_of_birth'].messages.future;
                } else {
                    valid.valid = true;
                    valid.message = '';
                }

            } else if( type == 'adult' ) {
                if( date_of_birth.day === null || date_of_birth.day.trim == ''
                    || date_of_birth.month === null || date_of_birth.month == ''
                    || date_of_birth.year === null || date_of_birth.year == '' ) {
                        valid.valid = false;
                        valid.message = vm.errors['date_of_birth'].messages.invalid_adult;

                        return valid;
                }
                else if( age > 17 && age < 66 || this.ageRestrictionCookieIsSet ) {
                    valid.valid = true;
                    valid.message = '';
                } else {
                    valid.valid = false;
                    valid.message = vm.errors['date_of_birth'].messages.too_young_adult;
                }
            }

            return valid;
        },
        checkValidAddressLine1: function( address_line_1 ) {
            let vm = this
            var valid = { valid: null, message: ''  };

            if( address_line_1 === null || address_line_1.trim() == '' ) {
                valid.valid = false;
                valid.message = vm.errors['address_line_1'].messages.empty_adult;
                return valid;
            }

            return valid;
        },
        checkValidAddressLine2: function( address_line_2 ) {
            let vm = this
            var valid = { valid: null, message: ''  };

            if( address_line_2 === null || address_line_2.trim() == '' ) {
                valid.valid = false;
                valid.message = vm.errors['address_line_2'].messages.empty_adult;
                return valid;
            }

            return valid;
        },
        checkValidPostcode: function( postcode, address_line_1, type ) {
            let vm = this
            var valid = { valid: null, message: ''  };

            var regex = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i;

            if( vm.errors['postcode'].required && ( postcode === '' || postcode === null ) ){
                valid.valid = false;
                valid.message = vm.errors['postcode'].messages.empty;

                if( type == 'adult' ) {
                    valid.message = vm.errors['postcode'].messages.empty_adult;
                }

            } else if( type == 'adult' && postcode == vm.application.address.postcode && address_line_1 == vm.application.address.line_1 ) {
                valid.valid = false;
                valid.message = vm.errors['postcode'].messages.invalid_adult_duplicate;
            } else {
                postcode = postcode.replace(/\s/g, "");

                if( regex.test(postcode) ) {
                    valid.valid = true;
                    valid.message = '';
                } else if( postcode !== null ) {
                    valid.valid = false;
                    valid.message = vm.errors['postcode'].messages.invalid;

                    if( type == 'adult' ) {
                        valid.message = vm.errors['postcode'].messages.invalid_adult;
                    }

                }
            }

            return valid;
        },
        cleanName: function( title, first_name, last_name ){
            let applicantName = '';

            if( title !== null && title !== '' && title !== 'n/a' ) {
                applicantName += this.capitalizeFirstLetter(title);
            }
            if( first_name !== null && first_name !== '' ) {
                applicantName += " " + this.capitalizeFirstLetter(first_name);
            }
            if( last_name !== null && last_name !== '' ) {
                applicantName += " " + this.capitalizeFirstLetter(last_name);
            }

            return applicantName;
        },
        getCurrentPrice: function( cover_level ) {
            let vm = this;
            var total = 0.00;

            if( vm.prices ) {
                total = Number(vm.prices.ppm['level_'+cover_level]);
            }

            return Number(total).toFixed(2);
        },
        getPremiumLabel: function() {
            let vm = this;
            var label = '';

            label = 'Monthly premium';

            return label;
        },
        getMainLevel: function() {
            let vm = this,
                cover_level = 1;

            cover_level = vm.application.cover_level;

            return cover_level;
        },
        getPriceLevelLabel( price_level ) {
            let vm = this;
            var price = '';

            if( vm.prices ) {
                price = Number(vm.prices.ppm['level_'+price_level]).toFixed(2);
            }

            return price;
        },
        sanitizeValue(str){
            return String(str).replace(/[^A-Za-z 0-9_'-/\\&/\\@]/gi, '');
        },
        ageRestrictionCookieIsSet() {
            var match = document.cookie.match(new RegExp('(^| )remove_age_restriction=([^;]+)'));

            if (match) {
                return true;
            }
            else {
                return false;
            }
        }
    }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-col" }, [
    _c("div", { staticClass: "formInner children-summary" }, [
      _c("div", { staticClass: "expander-box can-expand" }, [
        _vm._m(0),
        _vm._v(" "),
        Object.keys(_vm.application.additional_policies.children).length > 0
          ? _c(
              "div",
              { staticClass: "content" },
              [
                _vm._l(
                  _vm.application.additional_policies.children,
                  function (child, child_key) {
                    return _c(
                      "div",
                      {
                        key: "child_" + child_key,
                        staticClass: "child",
                        attrs: { "data-cy": "summary-child-" + child_key },
                      },
                      [
                        _c("p", [
                          _c("strong", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.cleanName(
                                  child.title,
                                  child.first_name,
                                  child.last_name
                                )
                              ),
                            },
                          }),
                        ]),
                        _c("p"),
                        _c("p", [
                          _c("strong", [_vm._v("Date of birth:")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.getDateOfBirth(child.date_of_birth))
                          ),
                        ]),
                      ]
                    )
                  }
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--primary",
                    on: {
                      click: function ($event) {
                        return _vm.returnToStepOne("edit")
                      },
                    },
                  },
                  [_vm._v("Edit")]
                ),
              ],
              2
            )
          : _c(
              "div",
              {
                staticClass: "content",
                attrs: { "data-cy": "summary-children" },
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--primary",
                    on: {
                      click: function ($event) {
                        return _vm.returnToStepOne("add")
                      },
                    },
                  },
                  [_vm._v("Edit")]
                ),
              ]
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _vm._v("Your Children's Cover"),
      _c("a", { staticClass: "expand arrowDown" }, [_vm._v("Expand")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "no-content" }, [
      _c("p", [
        _c("i", { staticClass: "fa fa-info" }),
        _vm._v("No children added"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
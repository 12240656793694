var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "table",
      { staticClass: "table-application" },
      [
        _c("payroll-table-header", {
          attrs: {
            benefits: _vm.benefits,
            buttons: _vm.buttons,
            "button-type": _vm.buttonType,
          },
        }),
        _vm._v(" "),
        _vm._l(_vm.benefits, function (benefit, benefit_key) {
          return [
            _c(
              "tr",
              { key: "benefit-header_" + benefit_key, staticClass: "header" },
              [
                _c("td", {
                  staticClass: "al",
                  domProps: { innerHTML: _vm._s(benefit.section_title) },
                }),
                _vm._v(" "),
                _c("td", [
                  _vm._v("\n                    Payback\n                "),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "pop-out-wrap" }, [
                    _c("a", { staticClass: "pop-out-link" }, [
                      _c("img", {
                        attrs: { src: _vm.images_path + "/icon-people.png" },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pop-out" }, [
                      _vm._v(
                        "Dependent children up to the age of 18 covered for free"
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "5" } }),
              ]
            ),
            _vm._v(" "),
            _vm._l(
              benefit.benefit,
              function (single_benefit, single_benefit_key) {
                return [
                  _c(
                    "tr",
                    {
                      key:
                        "benefit-details_" +
                        benefit_key +
                        "-" +
                        single_benefit_key,
                    },
                    [
                      _c(
                        "td",
                        {
                          staticClass: "al",
                          attrs: {
                            colspan: single_benefit.no_levels ? "8" : "1",
                          },
                        },
                        [
                          _c("div", { staticClass: "cell-content" }, [
                            _c("span", [
                              _c("strong", {
                                domProps: {
                                  innerHTML: _vm._s(single_benefit.title),
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(single_benefit.text),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "coverExpand",
                              attrs: {
                                src: _vm.images_path + "/white-arrow-up.svg",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      single_benefit.no_levels === false
                        ? [
                            _c("td", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.paybackLabel(single_benefit.payback)
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("td", [
                              _c("span", {
                                class:
                                  single_benefit.dependents == "yes"
                                    ? "true"
                                    : "false",
                                domProps: {
                                  innerHTML: _vm._s(single_benefit.dependents),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "tab-level-info tab-level-1",
                                class: _vm.isLevelActive(1),
                                attrs: { "data-level": "1" },
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.hoverColumns(1)
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.clearColumns()
                                  },
                                },
                              },
                              [
                                single_benefit.wording == "upto"
                                  ? _c("span", {
                                      staticClass: "lg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.wordingLabel(
                                            single_benefit.wording
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "money",
                                  domProps: {
                                    innerHTML: _vm._s(single_benefit.level_1),
                                  },
                                }),
                                _vm._v(" "),
                                single_benefit.wording != "upto"
                                  ? _c("span", {
                                      staticClass: "lg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.wordingLabel(
                                            single_benefit.wording
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "tab-level-info tab-level-2 alt",
                                class: _vm.isLevelActive(2),
                                attrs: { "data-level": "2" },
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.hoverColumns(2)
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.clearColumns()
                                  },
                                },
                              },
                              [
                                single_benefit.wording == "upto"
                                  ? _c("span", {
                                      staticClass: "lg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.wordingLabel(
                                            single_benefit.wording
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "money",
                                  domProps: {
                                    innerHTML: _vm._s(single_benefit.level_2),
                                  },
                                }),
                                _vm._v(" "),
                                single_benefit.wording != "upto"
                                  ? _c("span", {
                                      staticClass: "lg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.wordingLabel(
                                            single_benefit.wording
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "tab-level-info tab-level-3",
                                class: _vm.isLevelActive(3),
                                attrs: { "data-level": "3" },
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.hoverColumns(3)
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.clearColumns()
                                  },
                                },
                              },
                              [
                                single_benefit.wording == "upto"
                                  ? _c("span", {
                                      staticClass: "lg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.wordingLabel(
                                            single_benefit.wording
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "money",
                                  domProps: {
                                    innerHTML: _vm._s(single_benefit.level_3),
                                  },
                                }),
                                _vm._v(" "),
                                single_benefit.wording != "upto"
                                  ? _c("span", {
                                      staticClass: "lg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.wordingLabel(
                                            single_benefit.wording
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "tab-level-info tab-level-4 alt",
                                class: _vm.isLevelActive(4),
                                attrs: { "data-level": "4" },
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.hoverColumns(4)
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.clearColumns()
                                  },
                                },
                              },
                              [
                                single_benefit.wording == "upto"
                                  ? _c("span", {
                                      staticClass: "lg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.wordingLabel(
                                            single_benefit.wording
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "money",
                                  domProps: {
                                    innerHTML: _vm._s(single_benefit.level_4),
                                  },
                                }),
                                _vm._v(" "),
                                single_benefit.wording != "upto"
                                  ? _c("span", {
                                      staticClass: "lg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.wordingLabel(
                                            single_benefit.wording
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass: "tab-level-info tab-level-5",
                                class: _vm.isLevelActive(5),
                                attrs: { "data-level": "5" },
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.hoverColumns(5)
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.clearColumns()
                                  },
                                },
                              },
                              [
                                single_benefit.wording == "upto"
                                  ? _c("span", {
                                      staticClass: "lg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.wordingLabel(
                                            single_benefit.wording
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "money",
                                  domProps: {
                                    innerHTML: _vm._s(single_benefit.level_5),
                                  },
                                }),
                                _vm._v(" "),
                                single_benefit.wording != "upto"
                                  ? _c("span", {
                                      staticClass: "lg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.wordingLabel(
                                            single_benefit.wording
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  single_benefit.summary_text
                    ? _c(
                        "tr",
                        {
                          key:
                            "cover-info_" +
                            benefit_key +
                            "-" +
                            single_benefit_key,
                          staticClass: "coverInfo js-tab-coverInfo",
                        },
                        [
                          _c("td", { attrs: { colspan: "8" } }, [
                            _c("div", {
                              staticClass: "colHead",
                              domProps: {
                                innerHTML: _vm._s(single_benefit.summary_text),
                              },
                            }),
                            _vm._v(" "),
                            single_benefit.we_will_pay
                              ? _c("div", {
                                  staticClass: "col half",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      single_benefit.we_will_pay
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            single_benefit.we_will_not_pay
                              ? _c("div", {
                                  staticClass: "col half",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      single_benefit.we_will_not_pay
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              }
            ),
            _vm._v(" "),
            benefit.section_summary_text
              ? _c("tr", { key: "benefit-summary_" + benefit_key }, [
                  _vm._m(0, true),
                ])
              : _vm._e(),
          ]
        }),
        _vm._v(" "),
        _c("payroll-table-header", {
          attrs: {
            benefits: _vm.benefits,
            buttons: _vm.buttons,
            "button-type": _vm.buttonType,
          },
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "al", attrs: { colspan: "8" } }, [
      _c("strong", [_vm._v("Telephone helpline")]),
      _vm._v(
        " Available 24 hours a day, 365 days a year. Provided by Optum. Includes a medical helpline, telephone counselling, specialist legal, financial and debt information as well as online support on a wide range of life issues"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "premium-tax premium-tax--small" }, [
      _c("p", [_vm._v("Premiums include insurance premium tax (IPT).")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
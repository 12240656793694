<template>
    <div class="level-selector-table">
        <div class="mobile-tabs">
            <p>Choose a level of cover to view more details</p>
            <ul>
                <li><a class="tab-level-link" :class="{ 'active' : selected_level == '1' }" data-level="1">1</a></li>
                <li><a class="tab-level-link" :class="{ 'active' : selected_level == '2' }" data-level="2">2</a></li>
                <li><a class="tab-level-link" :class="{ 'active' : selected_level == '3' }" data-level="3">3</a></li>
                <li><a class="tab-level-link" :class="{ 'active' : selected_level == '4' }" data-level="4">4</a></li>
                <li><a class="tab-level-link" :class="{ 'active' : selected_level == '5' }" data-level="5">5</a></li>
            </ul>
        </div>
        <payroll-table :benefits="benefits" buttons="true" buttonType="choose"></payroll-table>
        <div class="mobile-tabs">
            <p>Choose a level of cover to view more details</p>
            <ul>
                <li><a class="tab-level-link" :class="{ 'active' : selected_level == '1' }" data-level="1">1</a></li>
                <li><a class="tab-level-link" :class="{ 'active' : selected_level == '2' }" data-level="2">2</a></li>
                <li><a class="tab-level-link" :class="{ 'active' : selected_level == '3' }" data-level="3">3</a></li>
                <li><a class="tab-level-link" :class="{ 'active' : selected_level == '4' }" data-level="4">4</a></li>
                <li><a class="tab-level-link" :class="{ 'active' : selected_level == '5' }" data-level="5">5</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../helpers/bus';

    import PayrollTable from './../pages/get-started/table/table';

    export default {
        props:['benefits'],
        components:{
            PayrollTable
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            )
        },
        created() {
        },
        data () {
            return {
                selected_level: 1
            }
        }
    }
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "level-selector-table" },
    [
      _c("div", { staticClass: "mobile-tabs" }, [
        _c("p", [_vm._v("Choose a level of cover to view more details")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "tab-level-link",
                class: { active: _vm.selected_level == "1" },
                attrs: { "data-level": "1" },
              },
              [_vm._v("1")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "tab-level-link",
                class: { active: _vm.selected_level == "2" },
                attrs: { "data-level": "2" },
              },
              [_vm._v("2")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "tab-level-link",
                class: { active: _vm.selected_level == "3" },
                attrs: { "data-level": "3" },
              },
              [_vm._v("3")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "tab-level-link",
                class: { active: _vm.selected_level == "4" },
                attrs: { "data-level": "4" },
              },
              [_vm._v("4")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "tab-level-link",
                class: { active: _vm.selected_level == "5" },
                attrs: { "data-level": "5" },
              },
              [_vm._v("5")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("payroll-table", {
        attrs: {
          benefits: _vm.benefits,
          buttons: "true",
          buttonType: "choose",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mobile-tabs" }, [
        _c("p", [_vm._v("Choose a level of cover to view more details")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                staticClass: "tab-level-link",
                class: { active: _vm.selected_level == "1" },
                attrs: { "data-level": "1" },
              },
              [_vm._v("1")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "tab-level-link",
                class: { active: _vm.selected_level == "2" },
                attrs: { "data-level": "2" },
              },
              [_vm._v("2")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "tab-level-link",
                class: { active: _vm.selected_level == "3" },
                attrs: { "data-level": "3" },
              },
              [_vm._v("3")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "tab-level-link",
                class: { active: _vm.selected_level == "4" },
                attrs: { "data-level": "4" },
              },
              [_vm._v("4")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "tab-level-link",
                class: { active: _vm.selected_level == "5" },
                attrs: { "data-level": "5" },
              },
              [_vm._v("5")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
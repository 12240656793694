<template>
    <div class="main-col note" v-html="important_notes">
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            )
        },
        created() {
            let vm = this,
                api_url = '/wp-json/acf/v3/options/option';

            axios.get(api_url)
                .then(response => { 
                    if( response.status == '200' ) {
                        vm.important_notes = response.data.acf.app_step_all_notes;
                    }
                })
                .catch( error => { });
        },
        mounted() {
        },
        methods: {
        },
        data () {
            return {
                important_notes: null
            }
        }
    }
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outlined" }, [
    _c(
      "a",
      {
        attrs: {
          target: "_blank",
          href: "/app/uploads/2023/12/@Good_All_Round_TCs_secured_20231101.pdf",
        },
      },
      [
        _c("img", { attrs: { src: this.images_path + "/icon-download.png" } }),
        _vm._v(
          "\n        Click here to download the cash plan terms and conditions.\n    "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
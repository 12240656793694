<template>
    <div class="floating-container">
        <div class="application-summary-box">
            <h2>Are your details correct?</h2>
            <div class="summary-row final-summary">
                <p class="total-title">Total premium</p>
                <p class="total-price"><b class="value" v-html="'&pound;'+total_application_cost"></b> <em v-html="price_level_label_simple"></em></p>
                <a class="btn btn--primary" :disabled="!is_stage_three_valid" @click="completeStageThree()">Submit</a>
            </div>
        </div>
        <application-download-terms></application-download-terms>
        <application-download-information-document></application-download-information-document>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../../../helpers/bus';

    import ApplicationDownloadTerms from '../../../blocks/application-download-terms';
    import ApplicationDownloadInformationDocument from '../../../blocks/application-download-information-document';

    export default {
        props:[],
        components:{
            ApplicationDownloadTerms,
            ApplicationDownloadInformationDocument
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
        },
        created() {
        },
        mounted() {
        },
        methods: {
            completeStageThree: function() {
                let vm = this,
                    api_url = '/wp-json/sovereign/v1/save-application';

                if( vm.is_stage_three_valid ) {
                    axios.post(api_url, {
                            application: vm.application,
                            stage: 'Completed'
                        })
                        .then(response => { 
                            if( response.status == '200' ) {
                                vm.$store.dispatch( 'updateApplication', [ 'application_id', response.data.id ] );
                                vm.$store.dispatch( 'updateStage', [ 'confirmation', true ] );

                                if (typeof window.dataLayer === 'object') {
                                    window.dataLayer.push({
                                        'event': 'application-complete',
                                        'level': vm.application.cover_level
                                    });
                                }

                                history.pushState(null, 'Thank You', window.location.pathname + '?stage=thank-you');
                                EventBus.$emit('updateStageURL');
                            }
                        })
                        .catch( error => { });
                } else {
                    EventBus.$emit('validateAllStep3');
                }
                    
            },
        },
        data () {
            return {
            }
        }
    }
</script>
<template>
    <div class="application-form-wrap" :class="{ 'loading' : loading }">
        <span class="loading-spinner" v-if="loading"></span>
        <div class="application-stage">
            <application-step-indicator></application-step-indicator>

            <application-level-selector></application-level-selector>
            <form id="appFormTwo" class="application-form" method="post">

                <div class="main-col validation-error-col" v-if="show_errors && is_stage_two_valid === false">
                    <div class="main-error">
                        <p>Please ensure all required fields are completed</p>
                    </div>
                </div>

                <fieldset class="main-col">
                    <div class="formInner">
                        <legend>Payment</legend>
                        <div class="form-text" v-html="payment_details"></div>

                        <fieldset class="confirmation-summary" v-if="declaration !== ''">
                            <div class="checkbox-container">
                                <label for="agreement" class="inline-label">
                                    <input type="checkbox" v-model="applicant_payment_confirmation" id="agreement" name="agreement" :class="{ 'has-error' : errors.payment_confirmation.valid === false }" data-cy="terms-agreement"/>
                                    <span v-html="declaration"></span>
                                </label>
                            </div>
                        </fieldset>
                        <span class="help-block confirmation-error form-error" v-if="errors.payment_confirmation.valid === false" v-html="errors.payment_confirmation.messages.empty" data-cy="terms-agreement-error"></span>

                        <div class="form-text" v-html="dd_details"></div>

                        <div class="input-group input-payroll">
                            <label for="payrollNumber"><sup>*</sup>Account holder name(s)</label>
                            <input v-model="applicant_account_holder_name" type="text" name="main[account_holder_name]" id="mainAccountHolderName" :class="{ 'has-error' : errors.account_holder_name.valid === false }" @blur="validateAccountHolderName();" data-cy="account-name"/>

                            <span class="help-block form-error" v-if="errors.account_holder_name.valid === false" v-html="errors.account_holder_name.message" data-cy="account-name-error"></span>
                        </div>

                        <div class="input-group input-sortcode multiple autotabbed">
                             <div class="input-group input-label">
                                <label for="sortCode"><sup>*</sup>Sort code</label>
                            </div>

                            <div class="input-group input-sortcode input-sortcode--1">
                                <input v-model="applicant_sort_code_1" type="number" name="main[sort_code][1]" id="mainSortCode1" :class="{ 'has-error' : errors.sort_code.valid === false }" maxlength="2" size="2" @keyup="tabSortCode2()" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190;" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" data-cy="account-sortcode-1"/>
                            </div>

                            <div class="input-group input-sortcode input-sortcode--2">
                                <input v-model="applicant_sort_code_2" type="number" name="main[sort_code][2]" id="mainSortCode2" :class="{ 'has-error' : errors.sort_code.valid === false }" maxlength="2" size="2" @keyup="tabSortCode3()" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190;" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" data-cy="account-sortcode-2"/>
                            </div>

                            <div class="input-group input-sortcode input-sortcode--3">
                                <input v-model="applicant_sort_code_3" type="number" name="main[sort_code][3]" id="mainSortCode3" :class="{ 'has-error' : errors.sort_code.valid === false }" maxlength="2" size="2" @blur="validatePaymentDetails();" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190;" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" data-cy="account-sortcode-3"/>
                            </div>

                            <span class="help-block form-error" v-if="errors.sort_code.valid === false" v-html="errors.sort_code.message" data-cy="account-sortcode-error"></span>
                        </div>

                        <div class="input-group input-account-number">
                            <label for="account_number"><sup>*</sup>Account number</label>
                            <input v-model="applicant_account_number" type="number" name="main[account_number]" id="mainAccountNumber" :class="{ 'has-error' : errors.account_number.valid === false }" maxlength="8" size="8" @blur="validatePaymentDetails();" onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190;" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" data-cy="account-number"/>

                            <span class="help-block form-error" v-if="errors.account_number.valid === false" v-html="errors.account_number.message" data-cy="account-number-error"></span>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="main-col">
                    <div class="formInner">
                        <div class="form-text" v-html="dd_guarantee_details"></div>

                        <legend>Direct Credit - Your claims will be paid into the above bank account</legend>
                        <div class="form-text" v-html="dc_details"></div>
                    </div>
                 </fieldset>

                <application-important-notes></application-important-notes>
                <application-summary-box></application-summary-box>

            </form>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../../helpers/bus';

    import ApplicationStepIndicator from '../../blocks/application-step-indicator';
    import ApplicationLevelSelector from '../../blocks/application-level-selector';

    import ApplicationImportantNotes from '../../blocks/application-important-notes';
    import ApplicationSummaryBox from '../../blocks/application-summary-box';

    export default {
        props:[],
        components:{
            ApplicationStepIndicator,
            ApplicationImportantNotes,
            ApplicationSummaryBox,
            ApplicationLevelSelector
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            applicant_payment_confirmation: {
                get () {
                    return this.application.payment_confirmation;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'payment_confirmation', value ] );
                    this.$store.dispatch( 'validateApplicationPaymentConfirmation', value );
                }
            },
            applicant_account_holder_name: {
                get () {
                    return this.application.account_holder_name;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'account_holder_name', value.trim() ] );
                }
            },
            applicant_sort_code_1: {
                get () {
                    return this.application.sort_code[0];
                },
                set (value) {
                    this.$store.dispatch( 'updateSortCode', [ 0, value.trim() ] );
                }
            },
            applicant_sort_code_2: {
                get () {
                    return this.application.sort_code[1];
                },
                set (value) {
                    this.$store.dispatch( 'updateSortCode', [ 1, value.trim() ] );
                }
            },
            applicant_sort_code_3: {
                get () {
                    return this.application.sort_code[2];
                },
                set (value) {
                    this.$store.dispatch( 'updateSortCode', [ 2, value.trim() ] );
                }
            },
            applicant_account_number: {
                get () {
                    return this.application.account_number;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'account_number', value.trim() ] );
                }
            },
        },
        created() {
            let vm = this,
                api_url = '/wp-json/acf/v3/options/option';

            axios.get(api_url)
                .then(response => {
                    if( response.status == '200' ) {
                        vm.payment_details = response.data.acf.app_step_two_payment_details;
                        vm.declaration = response.data.acf.app_step_two_confirmation;
                        vm.dd_details = response.data.acf.app_step_two_dd_details;
                        vm.dd_guarantee_details = response.data.acf.app_step_two_dd_guarantee_details;
                        vm.dc_details = response.data.acf.app_step_two_dc_details;

                        setTimeout( function(){
                            vm.loading = false;
                        }, 500);
                    }
                })
                .catch( error => { });
        },
        mounted() {
            var vm = this;

            EventBus.$on('validateAllStep2', function(data) {
                vm.validateStep2();
                vm.show_errors = true;
            });
        },
        methods: {
            tabSortCode2() {
                let vm = this;

                if( vm.applicant_sort_code_1.length == 2 ) {
                    jQuery('.input-sortcode--2 input').focus();
                }

            },
            tabSortCode3() {
                let vm = this;

                if( vm.applicant_sort_code_2.length == 2 ) {
                    jQuery('.input-sortcode--3 input').focus();
                }

            },
            validateAccountHolderName() {
                let vm = this;
                vm.$store.dispatch( 'validateApplication', [ 'account_holder_name', vm.application.account_holder_name ] );
            },
            validatePaymentDetails() {
                let vm = this,
                    api_url = '/wp-json/sovereign/v1/check-account-details';

                axios.post(api_url, {
                        application: vm.application
                    })
                    .then(response => {
                        if( response.status == '200' ) {

                            if( response.data.error ) {
                                if( response.data.error == 'sort_code' ) {
                                    vm.$store.dispatch( 'validateApplicationSortCodeSimple', false );
                                } else if( response.data.error == 'account_number' && vm.application.account_number !== null ) {
                                    vm.$store.dispatch( 'validateApplicationAccountNumberSimple', false );
                                } else if( response.data.error == 'account_number' && vm.application.account_number === null ) {
                                    vm.$store.dispatch( 'validateApplicationSortCodeSimple', true );
                                    vm.$store.dispatch( 'validateApplicationAccountNumberSimple', true );
                                } else if( response.data.error != 'sort_code' && response.data.error != 'account_number' ) {
                                    vm.$store.dispatch( 'validateApplicationSortCodeSimple', false );
                                    vm.$store.dispatch( 'validateApplicationAccountNumberSimple', false );
                                }
                            } else {
                                vm.$store.dispatch( 'validateApplicationAccountNumberSimple', true );
                                vm.$store.dispatch( 'validateApplicationSortCodeSimple', true );
                            }
                        }
                    })
                    .catch( error => {
                        console.log(error);
                    });
            },
            validateStep2() {
                let vm = this;

                vm.validatePaymentDetails();

                vm.$store.dispatch( 'validateApplication', [ 'account_holder_name', vm.application.account_holder_name ] );
                vm.$store.dispatch( 'validateApplicationSortCode', vm.application.sort_code );
                vm.$store.dispatch( 'validateApplicationAccountNumber', vm.application.account_number );
                vm.$store.dispatch( 'validateApplication', [ 'payment_confirmation', vm.application.applicant_payment_confirmation ] );
            },
        },
        data () {
            return {
                loading: true,
                payment_details: "",
                declaration: "",
                dd_details: "",
                dd_guarantee_details: "",
                dc_details: "",
                show_errors: false
            }
        }
    }
</script>
<template>
    <fieldset class="main-col add-adult-form">
        <div class="formInner no-bottom">
            <a class="addAdult addBox" :class="{ 'active' : this.editing, 'disabled' : Object.keys(application.additional_policies.adults).length >= 1 }" @click="toggleAddForm()" data-cy="add-adult-button">Add an adult - partner/family member</a>

            <div class="adultForm" :class="{ 'editing' : this.editing, 'hidden' : !this.editing }" data-cy="add-adult-form">
                <div class="level-selector-wrap">
                    <p class="coverHead">Their cover level</p>
                    <div class="wrap-x-slide">
                        <div class="level-selector">
                            <template v-for="level in [1, 2, 3, 4, 5]">
                                <label :key="'add-adult-level-' + level" :class="{ 'active' : adult.cover_level == level }" :data-cy="'add-adult-level-button-' + level">
                                    <span class="level">Level {{ level }}</span>
                                    <span class="prices-wrap">
                                        <span class="amount">&pound;{{ getPriceLevelLabel( level ) }}</span>
                                        <span class="pp" v-html="price_level_label_per_adult"></span>
                                    </span>
                                    <span>
                                        <input v-model="adult.cover_level" :value="level" data-validation="required" data-validation-error-msg="Please select a level of cover" class="level-select your-level-select" type="radio" name="adult[level]" :data-cy="'add-adult-level-' + level"/>
                                        <div class="checked"></div>
                                    </span>
                                </label>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="warnings premium-tax">
                    <p class="has-i"><i class="fa fa-info"></i>Premiums include insurance premium tax (IPT).</p>

                    <div class="adultConsent" v-if="Object.keys(application.additional_policies.adults).length < 1">
                        <p>By submitting an application on behalf of another adult i.e. partner/family member, you confirm that you have their consent to share their personal data with us.</p>
                    </div>
                </div>

				<div class="input-group multiple">
                    <div class="input-group input-title">
                        <label for="adultTitle"><sup>*</sup>Title</label>

                        <div class="select-wrapper">
                            <select name="adult[title]" v-model="adult.title" id="adultTitle" :class="{ 'has-error': valid_title !== null && valid_title.valid === false }" @blur="validateTitle()" data-cy="add-adult-title">
                                <option disabled value="">Select</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Ms">Ms</option>
                                <option value="Dr">Dr</option>
                                <option value="n/a">Prefer not to say</option>
                            </select>
                        </div>

                        <span class="help-block form-error" v-if="valid_title !== null && valid_title.valid === false" v-html="valid_title.message" data-cy="add-adult-title-error"></span>
                    </div>

                    <div class="input-group input-firstname">
                        <label for="adultFirstName"><sup>*</sup>First name(s)</label>
                        <input type="text" name="adult[firstname]" id="adultFirst" v-model="adult.first_name" :class="{ 'has-error': valid_first_name !== null && valid_first_name.valid === false }" @blur="validateFirstName()" data-cy="add-adult-firstname"/>

                        <span class="help-block form-error" v-if="valid_first_name !== null && valid_first_name.valid === false" v-html="valid_first_name.message" data-cy="add-adult-firstname-error"></span>
                    </div>

                    <div class="input-box input-lastname">
                        <label for="adultLastName"><sup>*</sup>Surname</label>
                        <input type="text" name="adult[lastname]" id="adultLastName" v-model="adult.last_name" :class="{ 'has-error': valid_last_name !== null && valid_last_name.valid === false }"  @blur="validateLastName()" data-cy="add-adult-lastname"/>

                        <span class="help-block form-error" v-if="valid_last_name !== null && valid_last_name.valid === false" v-html="valid_last_name.message" data-cy="add-adult-lastname-error"></span>
                    </div>
                </div>

                <div class="dob-wrap">
                    <label for="adultDOB"><sup>*</sup>Date of birth</label>
                    <div class="input-group multiple">

                        <div class="select-wrapper sm">
                            <select name="adult[date_of_birth][day]" v-model="adult.date_of_birth.day" :class="{ 'has-error' : valid_dob !== null && valid_dob.valid === false }" @change="updateDOB('day')" data-cy="add-adult-dob-day">
                                <option disabled value="">DD</option>
                                <option v-for="day in 31" :key="day">{{day}}</option>
                            </select>
                        </div>

                        <div class="select-wrapper sm">
                            <select name="adult[date_of_birth][month]" v-model="adult.date_of_birth.month" :class="{ 'has-error' : valid_dob !== null && valid_dob.valid === false }" @change="updateDOB('month')" data-cy="add-adult-dob-month">
                                <option disabled value="">MM</option>
                                <option v-for="month in 12" :key="month">{{month}}</option>
                            </select>
                        </div>

                        <div class="select-wrapper sm">
                            <select name="adult[date_of_birth][year]" v-model="adult.date_of_birth.year" :class="{ 'has-error' : valid_dob !== null && valid_dob.valid === false }" @change="updateDOB('year')" data-cy="add-adult-dob-year">
                                <option disabled value="">YYYY</option>
                                <option v-for="year in dob_year_range" :key="year">{{year}}</option>
                            </select>
                        </div>

                        <span class="help-block form-error" v-if="valid_dob.valid === false" v-html="valid_dob.message" data-cy="add-adult-dob-error"></span>
                    </div>
                </div>

                <div class="input-group">
                    <div class="input-group input-email">
                        <label for="adultEmail">Email <span class="input-desc">(this email must be different to yours)</span></label>
                        <input type="text" name="adult[email]" id="adultEmail" v-model="adult.email" :class="{ 'has-error' : valid_email !== null && valid_email.valid === false }" @blur="validateEmailAddress()" data-cy="add-adult-email"/>

                        <span class="help-block form-error" v-if="valid_email !== null && valid_email.valid === false" v-html="valid_email.message" data-cy="add-adult-email-error"></span>
                    </div>
                </div>

                <div class="postcode-wrap">
                    <label for="adultSearchPostcode">Postcode <span class="input-desc">(if their address is different to yours)</span></label>
                    <div class="input-group multiple">
                        <input v-model="search_postcode" type="text" id="adultSearchPostcode" @blur="cleanAddress()" />

                        <div class="housePicker">
                            <input type="button" value="Find Address" class="btn btn--primary" :disabled="search_postcode == null || search_postcode == ''" @click="lookupAddress()" />
                        </div>

                        <a class="reveal-address reveal-address--main" @click="revealAddress()" data-cy="add-adult-reveal-address">Enter your address manually</a>
                    </div>

                    <span class="help-block form-error" v-if="valid_search_postcode !== null && valid_search_postcode.valid === false" v-html="valid_search_postcode.message" data-cy="add-adult-postcode-search-error"></span>
                </div>

                <div class="hidden" id="adultAddressHide" data-cy="add-adult-address">
                    <label for="adultAddressSelect">Select your address</label>

                    <div class="select-wrapper">
                        <select name="adultAddressSelect" id="adultAddressSelect" @change="updateAddress()">
                            <option></option>
                        </select>
                    </div>
                </div>

                <div :class="{ 'hidden': adult.address_line_1 === null }" id="adultAddressLines">
                    <div class="input-group multiple">
                        <label for="adultAddressLine1">Address line 1</label>
                        <input v-model="adult.address_line_1" type="text" name="adult[addressLine1]" id="adultAddressLine1" :class="{ 'has-error': valid_postcode !== null && valid_postcode.valid === false }" @blur="validatePostcode()" data-cy="add-adult-address-1"/>
                        <span class="help-block form-error" v-if="valid_postcode !== null && valid_postcode.valid === false" v-html="valid_postcode.message" data-cy="adult-address-1-error"></span>
                    </div>

                    <label for="adultAddressLine2">Address line 2</label>
                    <input v-model="adult.address_line_2" type="text" name="adult[addressLine2]" id="adultAddressLine2" data-cy="add-adult-address-2"/>

                    <label for="adultAddressLine3">Address line 3</label>
                    <input v-model="adult.address_line_3" type="text" name="adult[addressLine3]" id="adultAddressLine3" data-cy="add-adult-address-3"/>

                    <label for="adultAddressLine4">Address line 4</label>
                    <input v-model="adult.address_line_4" type="text" name="adult[addressLine4]" id="adultAddressLine4" data-cy="add-adult-address-4"/>

                    <div class="postcode-wrap">
                        <label for="adultPostcode">Postcode</label>
                        <div class="input-group multiple">
                            <input v-model="adult.postcode" type="text" name="adult[postcode]" id="adultPostcode" :class="{ 'has-error': valid_postcode !== null && valid_postcode.valid === false }" @blur="validatePostcode()" data-cy="add-adult-address-postcode"/>
                            <span class="help-block form-error" v-if="valid_postcode !== null && valid_postcode.valid === false" v-html="valid_postcode.message" data-cy="add-adult-postcode-error"></span>
                        </div>
                    </div>
                </div>

                <div class="action-box">
                    <input type="button" value="Remove" class="btn btn-secondary" @click="removeAdultApplicant()" data-cy="add-adult-remove-button"/>
                    <input type="button" value="Confirm" class="btn btn--primary" :disabled="!is_valid" @click="addAdultApplicant()" data-cy="add-adult-confirm-button"/>
				</div>
			</div>
		</div>
	</fieldset>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            ageRestrictionCookieIsSet() {
                var match = document.cookie.match(new RegExp('(^| )remove_age_restriction=([^;]+)'));

                if (match) {
                    return true;
                }
                else {
                    return false;
                }
            },
            dob_year_range: function() {                
                var lower_range = 17,
                    upper_range = 66;

                if( this.ageRestrictionCookieIsSet ) {
                    lower_range = 0;
                    upper_range = 120;
                }

                var top_year = moment().subtract(lower_range, 'years').format('Y'),
                    bottom_year = moment().subtract(upper_range, 'years').format('Y'),
                    years = [],
                    year = 0;


                for(var i = top_year; i >= bottom_year; i--){
                    years[year] = i;
                    year++;
                }

                return years;
            },
            is_valid: function() {
                let vm = this,
                    valid = true;

                if( vm.adult.cover_level === null || vm.adult.cover_level == '' ) valid = false;
                if( vm.valid_title === null || vm.valid_title.valid === false ) valid = false;
                if( vm.valid_first_name === null || vm.valid_first_name.valid === false ) valid = false;
                if( vm.valid_last_name === null || vm.valid_last_name.valid === false ) valid = false;
                if( vm.valid_dob.valid === false || vm.valid_dob.valid === null ) valid = false;
                if( vm.valid_email !== null && vm.valid_email.valid === false ) valid = false;
                if( vm.valid_postcode !== null && vm.valid_postcode.valid === false ) valid = false;

                return valid;
            },
            valid_dob: function() {
                let vm = this,
                    valid_dob = [];

                if( vm.editing_dob.day && vm.editing_dob.month && vm.editing_dob.year ) {
                    valid_dob = vm.checkValidDateOfBirth( vm.adult.date_of_birth, 'adult' );
                } else {
                    valid_dob.valid = null;
                }

                return valid_dob;
            }
        },
        created() {
        },
        mounted() {
            let vm = this;

            vm.adult.cover_level = vm.application.cover_level;

            EventBus.$on('addAdult', function(data) {
                vm.addAdultApplicant();
            });

            EventBus.$on('updatePrimaryEmailAddress', function(data) {
                vm.validateEmailAddress();
            });

            EventBus.$on('updatePrimaryAddress', function(data) {
                vm.validatePostcode();
            });
        },
        methods: {
            addAdultApplicant: function(){
                let vm = this;

                if( vm.adult.first_name !== null ) {
                    vm.adult.first_name = vm.adult.first_name.trim();
                }

                if( vm.adult.last_name !== null ) {
                    vm.adult.last_name = vm.adult.last_name.trim();
                }

                if( vm.is_valid ) {
                    vm.$store.dispatch( 'addAdultApplicant', vm.adult );
                    vm.editing = false;
                    vm.search_postcode = null;
                    vm.adult = {
                        cover_level: vm.application.cover_level,
                        title: '',
                        first_name: null,
                        last_name: null,
                        email: null,
                        date_of_birth: {
                            day: '',
                            month: '',
                            year: '',
                        },
                        address_line_1: null,
                        address_line_2: null,
                        address_line_3: null,
                        address_line_4: null,
                        postcode: null
                    };
                    vm.editing_dob = {
                        day: false,
                        month: false,
                        year: false
                    };
                    vm.valid_title = null;
                    vm.valid_first_name = null;
                    vm.valid_last_name = null;
                    vm.valid_email = null;
                    jQuery(window).scrollTop(jQuery('.addAdult.addBox').offset().top - (jQuery('#site-header').outerHeight() + 30) );
                }
            },
            removeAdultApplicant: function(){
                let vm = this;
                vm.editing = false;
                vm.search_postcode = null;
                vm.adult = {
                    cover_level: vm.application.cover_level,
                    title: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                    date_of_birth: {
                        day: null,
                        month: null,
                        year: null,
                    },
                    address_line_1: null,
                    address_line_2: null,
                    address_line_3: null,
                    address_line_4: null,
                    postcode: null
                };
                vm.editing_dob = {
                    day: false,
                    month: false,
                    year: false
                };
                vm.valid_title = null;
                vm.valid_first_name = null;
                vm.valid_last_name = null;
                vm.valid_email = null;
                vm.valid_postcode = null;
            },
            toggleAddForm: function(){
                let vm = this;

                if( Object.keys(vm.application.additional_policies.adults).length < 1 ) {
                    vm.editing = !vm.editing;
                }
            },
            updateCoverLevel: function(cover_level){
                let vm = this;
                vm.adult.cover_level = cover_level;
            },
            updateDOB( type ) {
                let vm = this;
                vm.editing_dob[type] = true;
            },
            validateTitle() {
                let vm = this;
                if( vm.adult.title !== null ) {
                    vm.adult.title = vm.sanitizeValue(vm.adult.title);
                }
                vm.valid_title = vm.checkValidTitle( vm.adult.title, 'adult' );
            },
            validateFirstName() {
                let vm = this;
                if( vm.adult.first_name !== null ) {
                    vm.adult.first_name = vm.sanitizeValue(vm.adult.first_name);
                }
                vm.valid_first_name = vm.checkValidFirstName( vm.adult.first_name, 'adult' );
            },
            validateLastName() {
                let vm = this;
                if( vm.adult.last_name !== null ) {
                    vm.adult.last_name = vm.sanitizeValue(vm.adult.last_name);
                }
                vm.valid_last_name = vm.checkValidLastName( vm.adult.last_name, 'adult' );
            },
            validateEmailAddress() {
                let vm = this;
                vm.valid_email = vm.checkValidEmail(vm.adult.email);
            },
            validatePostcode() {
                let vm = this;
                if( vm.adult.postcode !== null ) {
                    vm.adult.postcode = vm.sanitizeValue(vm.adult.postcode);
                    vm.valid_postcode = vm.checkValidPostcode( vm.adult.postcode, vm.adult.address_line_1, 'adult' );
                }
            },
            revealAddress: function () {
                jQuery('#adultAddressLines').removeClass('hidden');
                jQuery('.reveal-address--main').hide();
            },
            cleanAddress() {
                let vm = this;

                vm.search_postcode = vm.sanitizeValue(vm.search_postcode);
                vm.valid_search_postcode = vm.checkValidPostcode( vm.search_postcode, false, 'adult' );

                jQuery('#adultAddressHide').addClass('hidden');
                jQuery('#adultAddressLines').addClass('hidden');
                jQuery('.reveal-address--main').show();
            },
            lookupAddress() {
                var pc = jQuery('#adultSearchPostcode').val();

                var params = {
                    action : 'sov_query_postcode',
                    pc : pc
                };

                jQuery.get('/wp/wp-admin/admin-ajax.php', params, function(response){
                    var json = jQuery.parseJSON(response);
                    if(json.response === true){
                        jQuery('#adultAddressHide').removeClass('hidden');
                        var options = '<option value="">-- Please select your address --</option>';
                        var results = json.results
                        jQuery.each(results, function(i, result){
                            options = options + '<option value="' + result.key + '">' + result.label  + '</option>';
                        });
                        jQuery('#adultAddressSelect').empty().append(options);
                    }
                });
            },
            updateAddress: function() {
                let vm = this,
                    selected = jQuery('#adultAddressSelect').val();

                var params = {
                    action : 'sov_get_address',
                    key : selected
                }

                jQuery.get('/wp/wp-admin/admin-ajax.php',params,function(response){
                    var json = jQuery.parseJSON(response);

                    if(json.response === true){
                        jQuery('#adultAddressLines').removeClass('hidden');

                        var results = json.results;

                        if( results.property !== undefined ){
                            vm.adult.address_line_1 = results.property;
                            vm.adult.address_line_2 = results.street;

                            if( results.locality ) {
                                vm.adult.address_line_3 = results.locality + ', ' + results.town;
                            } else {
                                vm.adult.address_line_3 = results.town;
                            }

                            vm.adult.address_line_4 = results.county;
                            vm.adult.postcode = results.postcode;

                        } else {
                            vm.adult.address_line_1 = results.street;

                            if( results.locality ) {
                                vm.adult.address_line_2 = results.locality + ', ' + results.town;
                            } else {
                                vm.adult.address_line_2 = results.town;
                            }

                            vm.adult.address_line_3 = results.county;
                            vm.adult.postcode = results.postcode;
                        }

                        vm.validatePostcode();
                    }
                });
            },
        },
        data () {
            return {
                adult: {
                    cover_level: null,
                    title: '',
                    first_name: null,
                    last_name: null,
                    email: null,
                    date_of_birth: {
                        day: '',
                        month: '',
                        year: '',
                    },
                    address_line_1: null,
                    address_line_2: null,
                    address_line_3: null,
                    address_line_4: null,
                    postcode: null
                },
                editing: false,
                editing_dob: {
                    day: false,
                    month: false,
                    year: false
                },
                search_postcode: '',
                valid_title: null,
                valid_first_name: null,
                valid_last_name: null,
                valid_email: null,
                valid_postcode: null,
                valid_search_postcode: null,
            }
        }
    }
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h4", [_vm._v("Your cover")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("div", { staticClass: "your-level" }, [
            _c("div", { staticClass: "sumCol" }, [
              _c("p", {
                staticClass: "name",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.cleanName(
                      _vm.application.title,
                      _vm.application.first_name,
                      _vm.application.last_name
                    )
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v("Level "),
                _c("b", {
                  staticClass: "number",
                  domProps: { innerHTML: _vm._s(_vm.application.cover_level) },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sumCol" }, [
              !_vm.is_step_1
                ? _c("p", [
                    _c(
                      "a",
                      {
                        staticClass: "edit",
                        on: {
                          click: function ($event) {
                            return _vm.returnToStepOne()
                          },
                        },
                      },
                      [_vm._v("Edit")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", [
                _c("span", { staticClass: "amount" }, [
                  _vm._v("£"),
                  _c("b", {
                    staticClass: "value",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.getCurrentPrice(_vm.application.cover_level)
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("em", {
                    domProps: {
                      innerHTML: _vm._s(_vm.price_level_label_simple),
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      Object.keys(_vm.application.additional_policies.adults).length > 0
        ? [
            _c("div", { staticClass: "part-summary" }, [
              _c("h4", [_vm._v("Your partner/family member’s cover")]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(
                  _vm.application.additional_policies.adults,
                  function (adult, adult_key) {
                    return _c("li", { key: "adult_" + adult_key }, [
                      _c("div", { staticClass: "adult-summary" }, [
                        _c("div", { staticClass: "sumCol" }, [
                          _c("p", {
                            staticClass: "name",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.cleanName(
                                  adult.title,
                                  adult.first_name,
                                  adult.last_name
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("Level "),
                            _c("b", {
                              staticClass: "number",
                              domProps: {
                                innerHTML: _vm._s(adult.cover_level),
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "sumCol" }, [
                          !_vm.is_step_1
                            ? _c("p", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "edit",
                                    on: {
                                      click: function ($event) {
                                        return _vm.returnToStepOne("adults")
                                      },
                                    },
                                  },
                                  [_vm._v("Edit")]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", [
                            _c("span", { staticClass: "amount" }, [
                              _vm._v("£"),
                              _c("b", {
                                staticClass: "value",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.getCurrentPrice(adult.cover_level)
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("em", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.price_level_label_simple
                                  ),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                    ])
                  }
                ),
                0
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.application.additional_policies.children).length > 0
        ? [
            _c("h4", [_vm._v("Your children’s cover")]),
            _vm._v(" "),
            _c("div", { staticClass: "child-summary" }, [
              _c(
                "ul",
                [
                  _vm._l(
                    _vm.application.additional_policies.children,
                    function (child, child_key) {
                      return [
                        child !== null
                          ? _c("li", { key: "child_" + child_key }, [
                              _c("div", {}, [
                                _c("div", { staticClass: "sumCol" }, [
                                  _c("p", {
                                    staticClass: "name",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.cleanName(
                                          child.title,
                                          child.first_name,
                                          child.last_name
                                        )
                                      ),
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "sumCol" }, [
                                  !_vm.is_step_1
                                    ? _c("p", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "edit",
                                            on: {
                                              click: function ($event) {
                                                return _vm.returnToStepOne(
                                                  "children"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Edit")]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._m(0, true),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("span", { staticClass: "amount" }, [_vm._v("FREE")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard", class: { loading: _vm.loading } },
    [
      _vm.loading ? _c("span", { staticClass: "loading-spinner" }) : _vm._e(),
      _vm._v(" "),
      _vm.benefits !== null
        ? _c(
            "div",
            { staticClass: "application-stage application-start" },
            [
              _c("div", { staticClass: "expander-box can-expand" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "content active" }, [
                  _c("ul", { staticClass: "flex" }, [
                    !_vm.ageRestrictionCookieIsSet
                      ? _c("li", [
                          _c("img", {
                            attrs: {
                              src: this.images_path + "/icon-18plus.png",
                            },
                          }),
                          _vm._v(" You must be age 18 or over "),
                          _c("br"),
                          _vm._v("and under the age of 66"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("li", [
                      _c("img", {
                        attrs: {
                          src: this.images_path + "/icon-uk-resident.png",
                        },
                      }),
                      _vm._v(" You must be a UK resident"),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("img", {
                        attrs: {
                          src: this.images_path + "/icon-directdebit.png",
                        },
                      }),
                      _vm._v(
                        " Please ensure you have your bank details to hand to complete the Direct Debit form"
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "expander-box can-expand" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", {
                  staticClass: "content active",
                  domProps: { innerHTML: _vm._s(_vm.select_cover_details) },
                }),
              ]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("payroll-table", {
                attrs: { benefits: _vm.benefits, buttons: "true" },
              }),
              _vm._v(" "),
              _vm._m(4),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _vm._v("Before you start, please check the following:"),
      _c("a", { staticClass: "expand arrowDown active" }, [_vm._v("Expand")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "expander-box can-expand" }, [
      _c("h2", [
        _vm._v(
          "Do you want to cover somebody else and pay for their policy as a third party?"
        ),
        _c("a", { staticClass: "expand arrowDown" }, [_vm._v("Expand")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("p", [
          _vm._v(
            "If you are here to buy a policy for somebody else and you would like to pay for their policy as a third party, please call us on "
          ),
          _c("strong", [_vm._v("0800 678 5605")]),
          _vm._v(
            " to do this. Lines are open Monday to Thursday from 9am to 5pm, and Fridays from 9am to 4pm."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _vm._v(
        "To start your application, simply choose your level of cover from the table below."
      ),
      _c("a", { staticClass: "expand arrowDown active" }, [_vm._v("Expand")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mobile-tabs" }, [
      _c("p", [_vm._v("Choose a level of cover to view more details")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              staticClass: "tab-level-link active",
              attrs: { "data-level": "1" },
            },
            [_vm._v("1")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            { staticClass: "tab-level-link", attrs: { "data-level": "2" } },
            [_vm._v("2")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            { staticClass: "tab-level-link", attrs: { "data-level": "3" } },
            [_vm._v("3")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            { staticClass: "tab-level-link", attrs: { "data-level": "4" } },
            [_vm._v("4")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            { staticClass: "tab-level-link", attrs: { "data-level": "5" } },
            [_vm._v("5")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mobile-tabs" }, [
      _c("p", [_vm._v("Choose a level of cover to view more details")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              staticClass: "tab-level-link active",
              attrs: { "data-level": "1" },
            },
            [_vm._v("1")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            { staticClass: "tab-level-link", attrs: { "data-level": "2" } },
            [_vm._v("2")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            { staticClass: "tab-level-link", attrs: { "data-level": "3" } },
            [_vm._v("3")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            { staticClass: "tab-level-link", attrs: { "data-level": "4" } },
            [_vm._v("4")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            { staticClass: "tab-level-link", attrs: { "data-level": "5" } },
            [_vm._v("5")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
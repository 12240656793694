<template>
    <div class="floating-container">
        <div class="application-summary-box">
            <h2>Summary</h2>
            <div class="text-wrap total-sum">
                <div class="sumCol ">
                    <p class="title">Total premium</p>
                    <p class="short"><small v-html="summary_line"></small></p>
                </div>
                <div class="sumCol">
                    <a class="edit" v-if="is_step_2" @click="editStageOne()">Edit</a>
                    <p class="price">&pound;<span class="number" v-html="total_application_cost"></span><span class="pm" v-html="price_level_label_simple"></span></p>
                </div>
            </div>
            <div class="move-btn">
                <a v-if="is_step_1" class="btn btn--primary" @click="completeStageOne()">Next</a>
                <a v-if="is_step_2" class="btn btn--primary" @click="completeStageTwo()">Next</a>
            </div>
            <div>
                <h3><a class="expand-link" @click="toggleExpandBox()">Your cover details</a></h3>
                <div class="expanable open">
                    <div class="fixed-height">
                        <application-summary-floating></application-summary-floating>
                    </div>
                </div>
            </div>
        </div>
        <application-download-terms></application-download-terms>
        <application-download-information-document></application-download-information-document>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from '../../helpers/bus';

    import ApplicationSummaryFloating from './application-summary-floating';
    import ApplicationDownloadTerms from './application-download-terms';
    import ApplicationDownloadInformationDocument from './application-download-information-document';

    export default {
        props:[],
        components:{
            ApplicationSummaryFloating,
            ApplicationDownloadTerms,
            ApplicationDownloadInformationDocument
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            is_step_1: function(){
                let vm = this;
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-1' ) {
                    return true;
                } else {
                    return false;
                }
            },
            is_step_2: function(){
                let vm = this;
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-2' ) {
                    return true;
                } else {
                    return false;
                }
            },
            summary_line: function() {
                let vm = this;
                var summaryLine = '';

                var adults = 1;
                var children = 0;

                if( Object.keys(vm.application.additional_policies.adults).length > 0 ){
                    adults = adults + Object.keys(vm.application.additional_policies.adults).length;
                }

                if( Object.keys(vm.application.additional_policies.children).length > 0 ){
                    children = children + Object.keys(vm.application.additional_policies.children).length;
                }

                var summaryLine = adults + 'x adult';

                if( adults > 1 ){
                    summaryLine += 's';
                }

                if( children > 0 ){
                    var child = (children == 1) ? 'child': 'children' ;
                    summaryLine += ' and ' + children + 'x ' + child;
                }

                return summaryLine;
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            completeStageOne: function() {
                let vm = this,
                    api_url = '/wp-json/sovereign/v1/save-application';

                if( vm.is_stage_one_valid ) {

                    EventBus.$emit('addAdult');
                    EventBus.$emit('addChild');

                    axios.post(api_url, {
                            application: vm.application,
                            stage: 'Stage Two'
                        })
                        .then(response => {
                            if( response.status == '200' ) {
                                vm.$store.dispatch( 'updateApplication', [ 'application_id', response.data.id ] );
                                vm.$store.dispatch( 'updateStage', [ 'step-2', true ] );

                                history.pushState(null, 'Step 2', window.location.pathname + '?stage=step-2');
                                EventBus.$emit('updateStageURL');
                            }
                        })
                        .catch( error => {
                            console.log(error);
                        });
                } else {
                    EventBus.$emit('validateAllStep1');
                    window.scrollTo(0, 0);
                }
            },
            completeStageTwo: function() {
                let vm = this,
                    api_url = '/wp-json/sovereign/v1/save-application';

                if( vm.is_stage_two_valid ) {
                    axios.post(api_url, {
                            application: vm.application,
                            stage: 'Stage Three'
                        })
                        .then(response => {
                            if( response.status == '200' ) {
                                vm.$store.dispatch( 'updateApplication', [ 'application_id', response.data.id ] );
                                vm.$store.dispatch( 'updateStage', [ 'step-3', true ] );

                                history.pushState(null, 'Step 3', window.location.pathname + '?stage=step-3');
                                EventBus.$emit('updateStageURL');
                            }
                        })
                        .catch( error => { });
                } else {
                    EventBus.$emit('validateAllStep2');
                    window.scrollTo(0, 0);
                }
            },
            editStageOne: function() {
                let vm = this,
                    api_url = '/wp-json/sovereign/v1/save-application';

                if( vm.is_stage_two_valid ) {
                    axios.post(api_url, {
                            application: vm.application,
                            stage: 'Stage Three'
                        })
                        .then(response => {
                            if( response.status == '200' ) {
                                vm.$store.dispatch( 'updateApplication', [ 'application_id', response.data.id ] );
                                vm.$store.dispatch( 'updateStage', [ 'step-1', true ] );

                                history.pushState(null, 'Step 1', window.location.pathname + '?stage=step-1');
                                EventBus.$emit('updateStageURL');
                            }
                        })
                        .catch( error => { });
                } else {
                    EventBus.$emit('validateAllStep2');

                    vm.$store.dispatch( 'updateStage', [ 'step-1', true ] );

                    history.pushState(null, 'Step 1', window.location.pathname + '?stage=step-1');
                    EventBus.$emit('updateStageURL');
                }
            },
            toggleExpandBox: function() {
                jQuery('.expand-link').toggleClass('open');
                jQuery('.expanable').slideToggle('fast');
            }
        },
        data () {
            return {
            }
        }
    }
</script>
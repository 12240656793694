var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "floating-container" },
    [
      _c("div", { staticClass: "application-summary-box" }, [
        _c("h2", [_vm._v("Are your details correct?")]),
        _vm._v(" "),
        _c("div", { staticClass: "summary-row final-summary" }, [
          _c("p", { staticClass: "total-title" }, [_vm._v("Total premium")]),
          _vm._v(" "),
          _c("p", { staticClass: "total-price" }, [
            _c("b", {
              staticClass: "value",
              domProps: {
                innerHTML: _vm._s("&pound;" + _vm.total_application_cost),
              },
            }),
            _vm._v(" "),
            _c("em", {
              domProps: { innerHTML: _vm._s(_vm.price_level_label_simple) },
            }),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn--primary",
              attrs: { disabled: !_vm.is_stage_three_valid },
              on: {
                click: function ($event) {
                  return _vm.completeStageThree()
                },
              },
            },
            [_vm._v("Submit")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("application-download-terms"),
      _vm._v(" "),
      _c("application-download-information-document"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./application-download-terms.vue?vue&type=template&id=7f53250e&"
import script from "./application-download-terms.vue?vue&type=script&lang=js&"
export * from "./application-download-terms.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ciaranwood/Sites/ponderosa/sites/sovereignhealthcare/html/web/app/themes/sovereign/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7f53250e')) {
      api.createRecord('7f53250e', component.options)
    } else {
      api.reload('7f53250e', component.options)
    }
    module.hot.accept("./application-download-terms.vue?vue&type=template&id=7f53250e&", function () {
      api.rerender('7f53250e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/scripts/vue/views/blocks/application-download-terms.vue"
export default component.exports
<template>
    <div class="dashboard" :class="{ 'loading' : loading }">
        <span class="loading-spinner" v-if="loading"></span>
        <div class="application-stage application-start" v-if="benefits !== null">
            <div class="expander-box can-expand">
                <h2>Before you start, please check the following:<a class="expand arrowDown active">Expand</a></h2>
                <div class="content active">
                    <ul class="flex">
                        <li v-if="!ageRestrictionCookieIsSet"><img :src="this.images_path + '/icon-18plus.png'" /> You must be age 18 or over <br>and under the age of 66</li>
                        <li><img :src="this.images_path + '/icon-uk-resident.png'" /> You must be a UK resident</li>
                        <li><img :src="this.images_path + '/icon-directdebit.png'" /> Please ensure you have your bank details to hand to complete the Direct Debit form</li>
                    </ul>
                </div>
            </div>
            <div class="expander-box can-expand">
                <h2>Do you want to cover somebody else and pay for their policy as a third party?<a class="expand arrowDown">Expand</a></h2>
                <div class="content">
                    <p>If you are here to buy a policy for somebody else and you would like to pay for their policy as a third party, please call us on <strong>0800 678 5605</strong> to do this. Lines are open Monday to Thursday from 9am to 5pm, and Fridays from 9am to 4pm.</p>
                </div>
            </div>

            <div class="expander-box can-expand">
                <h2>To start your application, simply choose your level of cover from the table below.<a class="expand arrowDown active">Expand</a></h2>
                <div class="content active" v-html="select_cover_details"></div>
            </div>

            <div class="mobile-tabs">
                <p>Choose a level of cover to view more details</p>
                <ul>
                    <li><a class="tab-level-link active" data-level="1">1</a></li>
                    <li><a class="tab-level-link" data-level="2">2</a></li>
                    <li><a class="tab-level-link" data-level="3">3</a></li>
                    <li><a class="tab-level-link" data-level="4">4</a></li>
                    <li><a class="tab-level-link" data-level="5">5</a></li>
                </ul>
            </div>

            <payroll-table :benefits="benefits" buttons="true"></payroll-table>

            <div class="mobile-tabs">
                <p>Choose a level of cover to view more details</p>
                <ul>
                    <li><a class="tab-level-link active" data-level="1">1</a></li>
                    <li><a class="tab-level-link" data-level="2">2</a></li>
                    <li><a class="tab-level-link" data-level="3">3</a></li>
                    <li><a class="tab-level-link" data-level="4">4</a></li>
                    <li><a class="tab-level-link" data-level="5">5</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../../helpers/bus';

    import PayrollTable from './../get-started/table/table';

    export default {
        props:[],
        components:{
            PayrollTable
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            ageRestrictionCookieIsSet() {
                var match = document.cookie.match(new RegExp('(^| )remove_age_restriction=([^;]+)'));
                
                if (match) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        created() {
        },
        mounted() {
            let vm = this,
                benefits_api_url = '/wp-json/sovereign/v1/get-benefits',
                options_api_url = '/wp-json/acf/v3/options/option';

            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);

            axios.get(benefits_api_url)
                .then(response => {
                    if( response.status == '200' ) {
                        vm.benefits = response.data;
                    }
                })
                .catch( error => { });

            axios.get(options_api_url)
                .then(response => {
                    if( response.status == '200' ) {
                        vm.select_cover_details = response.data.acf.app_select_cover_details;

                        jQuery('.tab-level-link').filter('.active').trigger('click');

                        setTimeout( function(){
                            vm.loading = false;
                        }, 500);
                    }
                })
                .catch( error => { });

            // vm.$ga.page({
            //     page: window.location.pathname + '',
            //     title: 'Apply Now',
            //     location: window.location.origin + window.location.pathname + ''
            // });

            if( params.get("stage") ) {
                history.pushState(null, 'Apply Now', window.location.pathname + '');
            }

            if(params.get("level")) {
                const level = params.get("level");

                // Remove 'active' class from all tab-level-links
                jQuery('.tab-level-link').removeClass('active');

                // Add 'active' class to select level
                jQuery(`.tab-level-link[data-level="${level}"]`).addClass('active');
            };
        },
        methods: {
            updatePaymentType( payment_type ) {
                let vm = this;

                vm.$store.dispatch( 'updatePaymentType', payment_type );
                vm.$store.dispatch( 'updateStage', [ 'select-cover', true ] );

                history.pushState(null, 'Select Cover', window.location.pathname + '?stage=select-cover');
                EventBus.$emit('updateStageURL');
            }
        },
        data () {
            return {
                benefits: false,
                loading: true,
                select_cover_details: ''
            }
        }
    }
</script>

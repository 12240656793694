var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("thead", [
    _c("tr", { staticClass: "header" }, [
      _c("td", { staticClass: "al", attrs: { colspan: "3" } }, [
        _vm._v("Levels of cover"),
      ]),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "tab-level-info tab-level-1",
          class: _vm.isLevelActive(1),
          attrs: { "data-level": "1" },
          on: {
            mouseover: function ($event) {
              return _vm.hoverColumns(1)
            },
            mouseleave: function ($event) {
              return _vm.clearColumns()
            },
          },
        },
        [_vm._v("Level 1")]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "tab-level-info tab-level-2",
          class: _vm.isLevelActive(2),
          attrs: { "data-level": "2" },
          on: {
            mouseover: function ($event) {
              return _vm.hoverColumns(2)
            },
            mouseleave: function ($event) {
              return _vm.clearColumns()
            },
          },
        },
        [_vm._v("Level 2")]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "tab-level-info tab-level-3",
          class: _vm.isLevelActive(3),
          attrs: { "data-level": "3" },
          on: {
            mouseover: function ($event) {
              return _vm.hoverColumns(3)
            },
            mouseleave: function ($event) {
              return _vm.clearColumns()
            },
          },
        },
        [_vm._v("Level 3")]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "tab-level-info tab-level-4",
          class: _vm.isLevelActive(4),
          attrs: { "data-level": "4" },
          on: {
            mouseover: function ($event) {
              return _vm.hoverColumns(4)
            },
            mouseleave: function ($event) {
              return _vm.clearColumns()
            },
          },
        },
        [_vm._v("Level 4")]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "tab-level-info tab-level-5",
          class: _vm.isLevelActive(5),
          attrs: { "data-level": "5" },
          on: {
            mouseover: function ($event) {
              return _vm.hoverColumns(5)
            },
            mouseleave: function ($event) {
              return _vm.clearColumns()
            },
          },
        },
        [_vm._v("Level 5")]
      ),
    ]),
    _vm._v(" "),
    _c("tr", [
      _c("td", {
        staticClass: "al",
        attrs: { colspan: "3" },
        domProps: { innerHTML: _vm._s(_vm.price_level_label) },
      }),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "tab-level-info tab-level-1",
          class: _vm.isLevelActive(1),
          attrs: { "data-level": "1" },
          on: {
            mouseover: function ($event) {
              return _vm.hoverColumns(1)
            },
            mouseleave: function ($event) {
              return _vm.clearColumns()
            },
          },
        },
        [
          _c("span", { staticClass: "money" }, [
            _vm._v("£" + _vm._s(_vm.getPriceLevelLabel(1))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "tab-level-info tab-level-2 alt",
          class: _vm.isLevelActive(2),
          attrs: { "data-level": "2" },
          on: {
            mouseover: function ($event) {
              return _vm.hoverColumns(2)
            },
            mouseleave: function ($event) {
              return _vm.clearColumns()
            },
          },
        },
        [
          _c("span", { staticClass: "money" }, [
            _vm._v("£" + _vm._s(_vm.getPriceLevelLabel(2))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "tab-level-info tab-level-3",
          class: _vm.isLevelActive(3),
          attrs: { "data-level": "3" },
          on: {
            mouseover: function ($event) {
              return _vm.hoverColumns(3)
            },
            mouseleave: function ($event) {
              return _vm.clearColumns()
            },
          },
        },
        [
          _c("span", { staticClass: "money" }, [
            _vm._v("£" + _vm._s(_vm.getPriceLevelLabel(3))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "tab-level-info tab-level-4 alt",
          class: _vm.isLevelActive(4),
          attrs: { "data-level": "4" },
          on: {
            mouseover: function ($event) {
              return _vm.hoverColumns(4)
            },
            mouseleave: function ($event) {
              return _vm.clearColumns()
            },
          },
        },
        [
          _c("span", { staticClass: "money" }, [
            _vm._v("£" + _vm._s(_vm.getPriceLevelLabel(4))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "tab-level-info tab-level-5",
          class: _vm.isLevelActive(5),
          attrs: { "data-level": "5" },
          on: {
            mouseover: function ($event) {
              return _vm.hoverColumns(5)
            },
            mouseleave: function ($event) {
              return _vm.clearColumns()
            },
          },
        },
        [
          _c("span", { staticClass: "money" }, [
            _vm._v("£" + _vm._s(_vm.getPriceLevelLabel(5))),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.buttons
      ? _c("tr", [
          _c("td", { staticClass: "al", attrs: { colspan: "3" } }, [
            _vm._v("Choose a level"),
          ]),
          _vm._v(" "),
          _c(
            "td",
            {
              staticClass: "tab-level-info tab-level-1",
              class: _vm.isLevelActive(1),
              attrs: { "data-level": "1" },
              on: {
                mouseover: function ($event) {
                  return _vm.hoverColumns(1)
                },
                mouseleave: function ($event) {
                  return _vm.clearColumns()
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "choice",
                  on: {
                    click: function ($event) {
                      return _vm.ctaHandler("1")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.button_label))]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "td",
            {
              staticClass: "tab-level-info tab-level-2 alt",
              class: _vm.isLevelActive(2),
              attrs: { "data-level": "2" },
              on: {
                mouseover: function ($event) {
                  return _vm.hoverColumns(2)
                },
                mouseleave: function ($event) {
                  return _vm.clearColumns()
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "choice",
                  on: {
                    click: function ($event) {
                      return _vm.ctaHandler("2")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.button_label))]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "td",
            {
              staticClass: "tab-level-info tab-level-3",
              class: _vm.isLevelActive(3),
              attrs: { "data-level": "3" },
              on: {
                mouseover: function ($event) {
                  return _vm.hoverColumns(3)
                },
                mouseleave: function ($event) {
                  return _vm.clearColumns()
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "choice",
                  on: {
                    click: function ($event) {
                      return _vm.ctaHandler("3")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.button_label))]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "td",
            {
              staticClass: "tab-level-info tab-level-4 alt",
              class: _vm.isLevelActive(4),
              attrs: { "data-level": "4" },
              on: {
                mouseover: function ($event) {
                  return _vm.hoverColumns(4)
                },
                mouseleave: function ($event) {
                  return _vm.clearColumns()
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "choice",
                  on: {
                    click: function ($event) {
                      return _vm.ctaHandler("4")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.button_label))]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "td",
            {
              staticClass: "tab-level-info tab-level-5",
              class: _vm.isLevelActive(5),
              attrs: { "data-level": "5" },
              on: {
                mouseover: function ($event) {
                  return _vm.hoverColumns(5)
                },
                mouseleave: function ($event) {
                  return _vm.clearColumns()
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "choice",
                  on: {
                    click: function ($event) {
                      return _vm.ctaHandler("5")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.button_label))]
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="step-indicator">
        <a :class="{ 'active' : is_step_1 }" @click="updateCurrentStage('stage-one')" data-cy="step-indicator-1">
            <div class="step">
                <span class="stepNum">1</span>
                <span class="title">Cover Details</span>
            </div>
        </a>

        <a :class="{ 'active' : is_step_2, 'disabled' : !is_stage_one_valid }" @click="updateCurrentStage('stage-two')" data-cy="step-indicator-2">
            <div class="step">
                <span class="stepNum">2</span>
                <span class="title">Direct Debit Details</span>
            </div>
        </a>

        <a :class="{ 'active' : is_step_3, 'disabled' : !is_stage_two_valid }" @click="updateCurrentStage('stage-three')" data-cy="step-indicator-3">
            <div class="step">
                <span class="stepNum">3</span>
                <span class="title">Check Details</span>
            </div>
        </a>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';
    import { EventBus } from './../../helpers/bus';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            is_step_1: function(){
                let vm = this;
                let uri = window.location.search.substring(1); 
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-1' ) {
                    return true;
                } else {
                    return false;
                }
            },
            is_step_2: function(){
                let vm = this;
                let uri = window.location.search.substring(1); 
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-2' ) {
                    return true;
                } else {
                    return false;
                }
            },
            is_step_3: function(){
                let vm = this;
                let uri = window.location.search.substring(1); 
                let params = new URLSearchParams(uri);

                if( params.get("stage") == 'step-3' ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            updateCurrentStage( stage ) {
                let vm = this;

                if( vm.stage !== stage ) {
                    if( stage == 'stage-one' ) {

                        vm.$store.dispatch( 'updateStage', [ 'step-1', true ] );

                        history.pushState(null, 'Step 1', window.location.pathname + '?stage=step-1');
                        EventBus.$emit('updateStageURL');

                    } else if( stage == 'stage-two' && vm.is_stage_one_valid ) {

                        vm.$store.dispatch( 'updateStage', [ 'step-2', true ] );

                        history.pushState(null, 'Step 2', window.location.pathname + '?stage=step-2');
                        EventBus.$emit('updateStageURL');

                    } else if( stage == 'stage-three' && vm.is_stage_one_valid && vm.is_stage_two_valid ) {

                        vm.$store.dispatch( 'updateStage', [ 'step-3', true ] );

                        history.pushState(null, 'Step 3', window.location.pathname + '?stage=step-3');
                        EventBus.$emit('updateStageURL');

                    }
                }

            }
        },
        data () {
            return {
            }
        }
    }
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.child !== null
    ? _c("fieldset", { staticClass: "main-col edit-child-form" }, [
        _c("div", { staticClass: "formInner no-bottom" }, [
          _c(
            "a",
            {
              staticClass: "editChild editBox",
              class: { active: this.editing },
              attrs: {
                "data-child-id": _vm.index,
                id: "childLink-" + _vm.index,
              },
              on: {
                click: function ($event) {
                  return _vm.toggleUpdateForm()
                },
              },
            },
            [_c("p", { domProps: { innerHTML: _vm._s(_vm.toggle_title) } })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "childForm",
              class: { editing: this.editing, hidden: !this.editing },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "input-group multiple" }, [
                _c("div", { staticClass: "input-group input-title" }, [
                  _c("label", { attrs: { for: "childTitle-" + _vm.index } }, [
                    _c("sup", [_vm._v("*")]),
                    _vm._v("Title"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "select-wrapper" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.child_title,
                            expression: "child_title",
                          },
                        ],
                        attrs: {
                          name: "child[" + _vm.index + "][title]",
                          id: "childTitle-" + _vm.index,
                          "data-cy": "edit-child-title",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.validateTitle()
                          },
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.child_title = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("Select"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Miss" } }, [
                          _vm._v("Miss"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Master" } }, [
                          _vm._v("Master"),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.valid_title !== null && _vm.valid_title.valid === false
                    ? _c("span", {
                        staticClass: "help-block form-error",
                        attrs: { "data-cy": "edit-child-title" },
                        domProps: {
                          innerHTML: _vm._s(_vm.valid_title.message),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-box input-firstname" }, [
                  _c(
                    "label",
                    { attrs: { for: "childFirstName-" + _vm.index } },
                    [_c("sup", [_vm._v("*")]), _vm._v("First name(s)")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.child_first_name,
                        expression: "child_first_name",
                      },
                    ],
                    attrs: {
                      type: "text",
                      name: "child[" + _vm.index + "][first]",
                      id: "'childFirstName-'+index",
                      "data-cy": "edit-child-firstname",
                    },
                    domProps: { value: _vm.child_first_name },
                    on: {
                      blur: function ($event) {
                        return _vm.validateFirstName()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.child_first_name = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.valid_first_name !== null &&
                  _vm.valid_first_name.valid === false
                    ? _c("span", {
                        staticClass: "help-block form-error",
                        attrs: { "data-cy": "edit-child-firstname-error" },
                        domProps: {
                          innerHTML: _vm._s(_vm.valid_first_name.message),
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-box input-lastname" }, [
                  _c(
                    "label",
                    { attrs: { for: "childLastName-" + _vm.index } },
                    [_c("sup", [_vm._v("*")]), _vm._v("Surname")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.child_last_name,
                        expression: "child_last_name",
                      },
                    ],
                    attrs: {
                      type: "text",
                      name: "child[" + _vm.index + "][lastname]",
                      id: "'childLastName-'+index",
                      "data-cy": "edit-child-lastname",
                    },
                    domProps: { value: _vm.child_last_name },
                    on: {
                      blur: function ($event) {
                        return _vm.validateLastName()
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.child_last_name = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.valid_last_name !== null &&
                  _vm.valid_last_name.valid === false
                    ? _c("span", {
                        staticClass: "help-block form-error",
                        attrs: { "data-cy": "edit-child-lastname-error" },
                        domProps: {
                          innerHTML: _vm._s(_vm.valid_last_name.message),
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dob-wrap" }, [
                _c("label", { attrs: { for: "childDOB-" + _vm.index } }, [
                  _c("sup", [_vm._v("*")]),
                  _vm._v("Date of birth"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-group multiple" }, [
                  _c("div", { staticClass: "select-wrapper sm" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.child_dob_day,
                            expression: "child_dob_day",
                          },
                        ],
                        class: { "has-error": _vm.valid_dob.valid === false },
                        attrs: {
                          name: "child[" + _vm.index + "][date_of_birth][day]",
                          "data-cy": "edit-child-dob-day",
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.child_dob_day = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("DD"),
                        ]),
                        _vm._v(" "),
                        _vm._l(31, function (day) {
                          return _c("option", { key: day }, [
                            _vm._v(_vm._s(day)),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "select-wrapper sm" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.child_dob_month,
                            expression: "child_dob_month",
                          },
                        ],
                        class: { "has-error": _vm.valid_dob.valid === false },
                        attrs: {
                          name:
                            "child[" + _vm.index + "][date_of_birth][month]",
                          "data-cy": "edit-child-dob-month",
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.child_dob_month = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("MM"),
                        ]),
                        _vm._v(" "),
                        _vm._l(12, function (month) {
                          return _c("option", { key: month }, [
                            _vm._v(_vm._s(month)),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "select-wrapper sm" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.child_dob_year,
                            expression: "child_dob_year",
                          },
                        ],
                        class: { "has-error": _vm.valid_dob.valid === false },
                        attrs: {
                          name: "child[" + _vm.index + "][date_of_birth][year]",
                          "data-cy": "edit-child-dob-year",
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.child_dob_year = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("YYYY"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.dob_year_range, function (year) {
                          return _c("option", { key: year }, [
                            _vm._v(_vm._s(year)),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.valid_dob.valid === false
                    ? _c("span", {
                        staticClass: "help-block form-error",
                        attrs: { "data-cy": "edit-child-dob-error" },
                        domProps: { innerHTML: _vm._s(_vm.valid_dob.message) },
                      })
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "action-box" }, [
                _c("input", {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", value: "Remove" },
                  on: {
                    click: function ($event) {
                      return _vm.removeChildApplicant()
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "btn btn--primary",
                  attrs: {
                    type: "button",
                    value: "Update",
                    disabled: !_vm.is_valid,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateChildApplicant()
                    },
                  },
                }),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "warnings premium-tax" }, [
      _c("p", { staticClass: "has-i" }, [
        _c("i", { staticClass: "fa fa-info" }),
        _vm._v(
          "Add up to four dependent children up to the age of 18 who live with you below."
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "childConsent" }, [
        _c("p", [
          _vm._v(
            "As a parent or legal guardian of the dependent child, you confirm you have the authority to share their personal data on their behalf."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
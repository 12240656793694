var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "application-form-wrap", class: { loading: _vm.loading } },
    [
      _vm.loading ? _c("span", { staticClass: "loading-spinner" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "application-stage" },
        [
          _c("application-step-indicator"),
          _vm._v(" "),
          _c("application-level-selector"),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "application-form",
              attrs: { id: "appFormTwo", method: "post" },
            },
            [
              _vm.show_errors && _vm.is_stage_two_valid === false
                ? _c("div", { staticClass: "main-col validation-error-col" }, [
                    _vm._m(0),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("fieldset", { staticClass: "main-col" }, [
                _c("div", { staticClass: "formInner" }, [
                  _c("legend", [_vm._v("Payment")]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "form-text",
                    domProps: { innerHTML: _vm._s(_vm.payment_details) },
                  }),
                  _vm._v(" "),
                  _vm.declaration !== ""
                    ? _c("fieldset", { staticClass: "confirmation-summary" }, [
                        _c("div", { staticClass: "checkbox-container" }, [
                          _c(
                            "label",
                            {
                              staticClass: "inline-label",
                              attrs: { for: "agreement" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.applicant_payment_confirmation,
                                    expression:
                                      "applicant_payment_confirmation",
                                  },
                                ],
                                class: {
                                  "has-error":
                                    _vm.errors.payment_confirmation.valid ===
                                    false,
                                },
                                attrs: {
                                  type: "checkbox",
                                  id: "agreement",
                                  name: "agreement",
                                  "data-cy": "terms-agreement",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.applicant_payment_confirmation
                                  )
                                    ? _vm._i(
                                        _vm.applicant_payment_confirmation,
                                        null
                                      ) > -1
                                    : _vm.applicant_payment_confirmation,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.applicant_payment_confirmation,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.applicant_payment_confirmation =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.applicant_payment_confirmation =
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.applicant_payment_confirmation = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.declaration),
                                },
                              }),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errors.payment_confirmation.valid === false
                    ? _c("span", {
                        staticClass: "help-block confirmation-error form-error",
                        attrs: { "data-cy": "terms-agreement-error" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.errors.payment_confirmation.messages.empty
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "form-text",
                    domProps: { innerHTML: _vm._s(_vm.dd_details) },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group input-payroll" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.applicant_account_holder_name,
                          expression: "applicant_account_holder_name",
                        },
                      ],
                      class: {
                        "has-error":
                          _vm.errors.account_holder_name.valid === false,
                      },
                      attrs: {
                        type: "text",
                        name: "main[account_holder_name]",
                        id: "mainAccountHolderName",
                        "data-cy": "account-name",
                      },
                      domProps: { value: _vm.applicant_account_holder_name },
                      on: {
                        blur: function ($event) {
                          return _vm.validateAccountHolderName()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.applicant_account_holder_name =
                            $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.account_holder_name.valid === false
                      ? _c("span", {
                          staticClass: "help-block form-error",
                          attrs: { "data-cy": "account-name-error" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.errors.account_holder_name.message
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-group input-sortcode multiple autotabbed",
                    },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-group input-sortcode input-sortcode--1",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.applicant_sort_code_1,
                                expression: "applicant_sort_code_1",
                              },
                            ],
                            class: {
                              "has-error": _vm.errors.sort_code.valid === false,
                            },
                            attrs: {
                              type: "number",
                              name: "main[sort_code][1]",
                              id: "mainSortCode1",
                              maxlength: "2",
                              size: "2",
                              onkeydown:
                                "return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190;",
                              oninput:
                                "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                              "data-cy": "account-sortcode-1",
                            },
                            domProps: { value: _vm.applicant_sort_code_1 },
                            on: {
                              keyup: function ($event) {
                                return _vm.tabSortCode2()
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.applicant_sort_code_1 = $event.target.value
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-group input-sortcode input-sortcode--2",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.applicant_sort_code_2,
                                expression: "applicant_sort_code_2",
                              },
                            ],
                            class: {
                              "has-error": _vm.errors.sort_code.valid === false,
                            },
                            attrs: {
                              type: "number",
                              name: "main[sort_code][2]",
                              id: "mainSortCode2",
                              maxlength: "2",
                              size: "2",
                              onkeydown:
                                "return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190;",
                              oninput:
                                "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                              "data-cy": "account-sortcode-2",
                            },
                            domProps: { value: _vm.applicant_sort_code_2 },
                            on: {
                              keyup: function ($event) {
                                return _vm.tabSortCode3()
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.applicant_sort_code_2 = $event.target.value
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-group input-sortcode input-sortcode--3",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.applicant_sort_code_3,
                                expression: "applicant_sort_code_3",
                              },
                            ],
                            class: {
                              "has-error": _vm.errors.sort_code.valid === false,
                            },
                            attrs: {
                              type: "number",
                              name: "main[sort_code][3]",
                              id: "mainSortCode3",
                              maxlength: "2",
                              size: "2",
                              onkeydown:
                                "return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190;",
                              oninput:
                                "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                              "data-cy": "account-sortcode-3",
                            },
                            domProps: { value: _vm.applicant_sort_code_3 },
                            on: {
                              blur: function ($event) {
                                return _vm.validatePaymentDetails()
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.applicant_sort_code_3 = $event.target.value
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errors.sort_code.valid === false
                        ? _c("span", {
                            staticClass: "help-block form-error",
                            attrs: { "data-cy": "account-sortcode-error" },
                            domProps: {
                              innerHTML: _vm._s(_vm.errors.sort_code.message),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-group input-account-number" },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.applicant_account_number,
                            expression: "applicant_account_number",
                          },
                        ],
                        class: {
                          "has-error":
                            _vm.errors.account_number.valid === false,
                        },
                        attrs: {
                          type: "number",
                          name: "main[account_number]",
                          id: "mainAccountNumber",
                          maxlength: "8",
                          size: "8",
                          onkeydown:
                            "return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190;",
                          oninput:
                            "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                          "data-cy": "account-number",
                        },
                        domProps: { value: _vm.applicant_account_number },
                        on: {
                          blur: function ($event) {
                            return _vm.validatePaymentDetails()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.applicant_account_number = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.account_number.valid === false
                        ? _c("span", {
                            staticClass: "help-block form-error",
                            attrs: { "data-cy": "account-number-error" },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.errors.account_number.message
                              ),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("fieldset", { staticClass: "main-col" }, [
                _c("div", { staticClass: "formInner" }, [
                  _c("div", {
                    staticClass: "form-text",
                    domProps: { innerHTML: _vm._s(_vm.dd_guarantee_details) },
                  }),
                  _vm._v(" "),
                  _c("legend", [
                    _vm._v(
                      "Direct Credit - Your claims will be paid into the above bank account"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "form-text",
                    domProps: { innerHTML: _vm._s(_vm.dc_details) },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("application-important-notes"),
              _vm._v(" "),
              _c("application-summary-box"),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-error" }, [
      _c("p", [_vm._v("Please ensure all required fields are completed")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "payrollNumber" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Account holder name(s)"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group input-label" }, [
      _c("label", { attrs: { for: "sortCode" } }, [
        _c("sup", [_vm._v("*")]),
        _vm._v("Sort code"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "account_number" } }, [
      _c("sup", [_vm._v("*")]),
      _vm._v("Account number"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
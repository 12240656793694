<template>
   <div class="main-col summary-large-text">
        <fieldset class="formInner declaration-summary" v-if="declaration !== ''">
            <legend>Declaration</legend>
            <div v-html="declaration"></div>
        </fieldset>
        <fieldset class="formInner dpa-summary" v-if="dpa !== ''">
            <legend>Data Protection Act</legend>
            <div v-html="dpa"></div>
        </fieldset>
        <fieldset class="formInner marketing-summary" v-if="marketing !== ''">
            <legend>Your Marketing Contact Preferences</legend>
            <div v-html="marketing"></div>
            <div class="marketing-options">
                <div class="checkbox-container">
                    <label for="marketingPost" class="inline-label">
                        <input type="checkbox" v-model="application.marketing.post" id="marketingPost" name="marketingPost" /> 
                        <span>Post</span>
                    </label>
                </div>
                <div class="checkbox-container">
                    <label for="marketingPhone" class="inline-label">
                        <input type="checkbox" v-model="application.marketing.phone" id="marketingPhone" name="marketingPhone" /> 
                        <span>Phone</span>
                    </label>
                </div>
                <div class="checkbox-container">
                    <label for="marketingEmail" class="inline-label">
                        <input type="checkbox" v-model="application.marketing.email" id="marketingEmail" name="marketingEmail" /> 
                        <span>Email</span>
                    </label>
                </div>
                <div class="checkbox-container">
                    <label for="marketingText" class="inline-label">
                        <input type="checkbox" v-model="application.marketing.text" id="marketingText" name="marketingText" /> 
                        <span>Text</span>
                    </label>
                </div>
            </div>
        </fieldset>
        <fieldset class="formInner confirmation-summary" v-if="confirmation !== ''">
            <legend>Confirmation</legend>
            <div v-html="confirmation"></div>
            <div class="checkbox-container">
                <label for="agreement" class="inline-label">
                    <input type="checkbox" v-model="applicant_declaration" id="agreement" name="agreement" data-cy="summary-agreement"/>
                    <span>I have read and agree to the policy terms and conditions and declaration above.</span>
                </label>
            </div>
        </fieldset>
        <span class="help-block form-error" v-if="errors.confirmation.valid === false" v-html="errors.confirmation.message" data-cy="summary-agreement-error"></span>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    export default {
        props:[],
        components:{
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            ),
            applicant_declaration: {
                get () {
                    return this.application.confirmation;
                },
                set (value) {
                    this.$store.dispatch( 'updateApplication', [ 'confirmation', value ] );
                    this.$store.dispatch( 'validateApplicationConfirmation', value );
                }
            },
        },
        created() {
            let vm = this,
                api_url = '/wp-json/acf/v3/options/option';

            axios.get(api_url)
                .then(response => { 
                    if( response.status == '200' ) {
                        vm.declaration = response.data.acf.app_step_three_declaration;
                        vm.dpa = response.data.acf.app_step_three_dpa;
                        vm.marketing = response.data.acf.app_step_three_marketing;
                        vm.confirmation = response.data.acf.app_step_three_confirmation;
                    }
                })
                .catch( error => { });
        },
        mounted() {
            let vm = this;
        },
        methods: {
            updateConfirmation: function() {
                let vm = this;
            }
        },
        data () {
            return {
                declaration: '',
                dpa: '',
                marketing: '',
                confirmation: ''
            }
        },
    }
</script>
<template>
    <div>
        <table class="table-application">
            <payroll-table-header :benefits="benefits" :buttons="buttons" :button-type="buttonType"></payroll-table-header>
            <template v-for="(benefit, benefit_key) in benefits">
                <tr class="header" :key="'benefit-header_'+benefit_key">
                    <td class="al" v-html="benefit.section_title"></td>
                    <td>
                        Payback
                    </td>
                    <td>
                        <div class="pop-out-wrap">
                            <a class="pop-out-link">
                                <img :src="images_path + '/icon-people.png'" />
                            </a>
                            <div class="pop-out">Dependent children up to the age of 18 covered for free</div>
                        </div>
                    </td>
                    <td colspan="5"></td>
                </tr>
                <template v-for="(single_benefit, single_benefit_key) in benefit.benefit">
                    <tr :key="'benefit-details_'+benefit_key+'-'+single_benefit_key">
                        <td class="al" :colspan="single_benefit.no_levels ? '8' : '1'">
                            <div class="cell-content">
                                <span><strong v-html="single_benefit.title"></strong> <span v-html="single_benefit.text"></span></span>
                                <img class="coverExpand" :src="images_path + '/white-arrow-up.svg'" />
                            </div>
                        </td>
                        <template v-if="single_benefit.no_levels === false">
                            <td v-html="paybackLabel(single_benefit.payback)"></td>
                            <td>
                                <span :class="(single_benefit.dependents == 'yes') ? 'true' : 'false'" v-html="single_benefit.dependents"></span>
                            </td>
                            <td :class="isLevelActive(1)" class="tab-level-info tab-level-1" data-level="1" @mouseover="hoverColumns(1)" @mouseleave="clearColumns()">
                                <span class="lg" v-if="single_benefit.wording == 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                                <span class="money" v-html="single_benefit.level_1"></span>
                                <span class="lg" v-if="single_benefit.wording != 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                            </td>
                            <td :class="isLevelActive(2)" class="tab-level-info tab-level-2 alt" data-level="2" @mouseover="hoverColumns(2)" @mouseleave="clearColumns()">
                                <span class="lg" v-if="single_benefit.wording == 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                                <span class="money" v-html="single_benefit.level_2"></span>
                                <span class="lg" v-if="single_benefit.wording != 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                            </td>
                            <td :class="isLevelActive(3)" class="tab-level-info tab-level-3" data-level="3" @mouseover="hoverColumns(3)" @mouseleave="clearColumns()">
                                <span class="lg" v-if="single_benefit.wording == 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                                <span class="money" v-html="single_benefit.level_3"></span>
                                <span class="lg" v-if="single_benefit.wording != 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                            </td>
                            <td :class="isLevelActive(4)" class="tab-level-info tab-level-4 alt" data-level="4" @mouseover="hoverColumns(4)" @mouseleave="clearColumns()">
                                <span class="lg" v-if="single_benefit.wording == 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                                <span class="money" v-html="single_benefit.level_4"></span>
                                <span class="lg" v-if="single_benefit.wording != 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                            </td>
                            <td :class="isLevelActive(5)" class="tab-level-info tab-level-5" data-level="5" @mouseover="hoverColumns(5)" @mouseleave="clearColumns()">
                                <span class="lg" v-if="single_benefit.wording == 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                                <span class="money" v-html="single_benefit.level_5"></span>
                                <span class="lg" v-if="single_benefit.wording != 'upto'" v-html="wordingLabel(single_benefit.wording)"></span>
                            </td>
                        </template>
                    </tr>
                    <tr v-if="single_benefit.summary_text" :key="'cover-info_'+benefit_key+'-'+single_benefit_key" class="coverInfo js-tab-coverInfo" >
                        <td colspan="8">
                            <div class="colHead" v-html="single_benefit.summary_text"></div>
                            <div class="col half" v-if="single_benefit.we_will_pay" v-html="single_benefit.we_will_pay"></div>
                            <div class="col half" v-if="single_benefit.we_will_not_pay" v-html="single_benefit.we_will_not_pay"></div>
                        </td>
                    </tr>
                </template>
                <tr v-if="benefit.section_summary_text" :key="'benefit-summary_'+benefit_key">
                    <td class="al" colspan="8"><strong>Telephone helpline</strong> Available 24 hours a day, 365 days a year. Provided by Optum. Includes a medical helpline, telephone counselling, specialist legal, financial and debt information as well as online support on a wide range of life issues</td>
                </tr>
            </template>
            <payroll-table-header :benefits="benefits" :buttons="buttons" :button-type="buttonType"></payroll-table-header>
        </table>
        <div class="premium-tax premium-tax--small">
            <p>Premiums include insurance premium tax (IPT).</p>
        </div>
    </div>
</template>
<script>
    import { mapState, mapMutations } from 'vuex';

    import PayrollTableHeader from './table-header';

    export default {
        props:[ 'benefits', 'buttons', 'buttonType' ],
        components:{
            PayrollTableHeader
        },
        computed: {
            ...mapState(
                [ 'stages', 'application', 'errors', 'prices' ],
            )
        },
        created() {
        },
        mounted() {
        },
        methods: {
            clearColumns() {
                jQuery('.tab-level-info').removeClass('hovering');
            },
            hoverColumns( index ) {
                jQuery('.tab-level-info').removeClass('hovering');
                jQuery('.tab-level-'+index).addClass('hovering');
            },
            isLevelActive: function (level) {
                let vm = this;

                if (vm.application.cover_level) {
                    return vm.application.cover_level == level ? 'active' : '';
                } else {
                    return level == 1 ? 'active' : '';
                };
            },
            paybackLabel( payback ) {
                var label = '';
                switch( payback ){
                    case 'fifty':
                        label = '<span class="bt num">50%</span>';
                        break;
                    case 'hundred':
                        label = '<span class="bt num">100%</span>';
                        break;
                    case 'max-7-nights':
                        label = '<span class="bt">Max <em>7</em> days/nights<span>';
                        break;
                    case 'fixed-amount':
                        label = '<span class="bt">Fixed<br /> amount</span>';
                        break;
                    default:
                        label = '';
                        break;
                }
                return label;
            },
            wordingLabel( wording ) {
                var label = '';

                switch( wording ){
                    case 'per-child':
                        label = 'per child';
                        break;
                    case 'per-day':
                        label = 'per day/night';
                        break;
                    case 'upto':
                        label = 'up to';
                        break;
                    default:
                        label = '';
                        break;
                }
                return label;
            }
        },
        data () {
            return {
            }
        }
    }
</script>
